import { type TransactionRequest, toHex } from "viem";
// move to other lib depending on Network

import type { AnyCell, SheetProxy } from "@okcontract/cells";
import {
  type CacheQuery,
  type NetworkTXQueryType,
  OKWidgetApproveStep,
  OKWidgetSigStep,
  type OKWidgetStep,
  type OKWidgetStepType,
  isToken,
  prefixFromType,
  type_of_cache_query
} from "@okcontract/coredata";
import type { Environment, Rational } from "@okcontract/lambdascript";
import {
  type Address,
  type ChainAddress,
  type ChainType,
  type LocalRPCSubscriber,
  type Network,
  estimateGas,
  isNullAddr
} from "@okcontract/multichain";

import type { OKCore } from "./coreExecution";
import { ERC721 } from "./erc721";
import type { OKPage } from "./instance";

/**
 * Step
 */
// export type Step =
//   | (OKWidgetStep & { done: boolean; tx?: Address })
//   | (OKWidgetStep & ApprovalStep)
//   | (OKWidgetStep & ERC721ApprovalStep);

// @todo better types to diff Approval an ERCApproval
export type Step<T extends OKWidgetStepType> = OKWidgetStep<T> &
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  (T extends typeof OKWidgetApproveStep ? Approval & ERC721ApprovalStep : {});

export type ApprovalStep = OKWidgetStep<typeof OKWidgetApproveStep> &
  (Approval | ERC721ApprovalStep);

export type Stepper = {
  /** current step*/
  cur: number;
  /** total nb steps without approvals*/
  sub_total: number;
  /** steps */
  steps: Step<OKWidgetStepType>[];
  /** @deprecated */
  txs?: string[];
  /** is loading from a tx */
  loading: boolean;
};

export type Approval = {
  // token label
  // label: string;
  // token contract addr
  // addr: Address;
  // allowed amount desired
  amount?: number | bigint;
  // current allowance addr
  allowance?: bigint;
  // has been approved
  done: boolean;
  // spender addr
  spender: Address<Network>;
  // tx addr
  tx?: Address<Network>;
  // @todo definition
  def: unknown;
};

export type ERC721ApprovalStep = {
  tokenID?: number | bigint;
  done: boolean;
  spender: Address<Network>;
  // tx addr
  tx?: Address<Network>;
  // definition
  def: unknown;
};

// @todo to rewrite
// export const addNTXApprovalStep = async (
//   local: LocalRPCSubscriber,
//   env: Environment,
//   stepper: Stepper,
//   step: Step,
//   ntx: NetworkTX
// ) => {
//   // @todo FIXME getApprovalSteps should take the NetworkApproval[] directly
//   // there should not necessarily be a single spender
//   const spender = ntx.apv.length > 0 ? ntx.apv[0].s : ntx.to;
//   const allowances = [
//     [ntx.p?.tokenA, ntx.p.amount] as [Address, number | bigint],
//   ];
//   const approvalSteps = await Promise.all(
//     allowances.map(async (al) => {
//       const app = await approval(local, step, spender, env)(al)?.get();
//       if (app instanceof Error) return null;
//       return app;
//     })
//   );
//   if (approvalSteps instanceof Error) return stepper;

//   const steps = [
//     ...approvalSteps,
//     ...stepper.steps.filter((s) => s.sty !== OKWidgetApproveStep),
//   ] as Step[];

//   return {
//     ...stepper,
//     // cur: get_first_unfinished_step({
//     //   ...stepper,
//     //   steps: steps,
//     // }),
//     steps: add_approval_step_refs(steps),
//     $$update: false,
//   };
// };

/**
 * approval computes the approval parameters for all steps.
 * @param ch chain
 * @param spender address
 * @deprecated
 * @returns ApprovalStep
 */
export const approval =
  (
    instance: OKPage,
    step: Step<OKWidgetStepType>,
    spender: Address<Network>,
    env: Environment
  ) =>
  ([evm, desiredAmount, def]: [ChainAddress, bigint | number, unknown]) => {
    const proxy = instance.proxy;
    // @todo cells ?
    const evmp = proxy.new(evm);
    const abi = instance._getABI(evmp);
    const parsedAbi = abi.map((_abi) => _abi.parsed);
    const isErc721 = abi.map((_abi) => _abi.erc.includes(ERC721));
    const al = instance._getAllowanceForWallet(
      instance.core.WalletID,
      evmp,
      proxy.new(spender, "approval.spender")
    );

    const isApprovedForAllEvm = proxy.map(
      [evmp, isErc721],
      (_evm, _isErc721) => (_isErc721 && desiredAmount === -1 ? _evm : null),
      "approval.isApprovedForAllEvm"
    );
    const isApprovedForAll = instance.rpc.call(
      isApprovedForAllEvm,
      parsedAbi,
      proxy.new("isApprovedForAll", "approval.isApprovedForAll.functionName"),
      proxy.new(
        // @todo define $self as constant
        [proxy.new(env?.value("$self")), proxy.new(spender)],
        "approval.isApprovedForAll.args"
      ),
      proxy.new({ name: "steps.approval.isApprovedForAll" })
    );
    const isGetApprovedEvm = proxy.map(
      [evmp, isErc721],
      (_evm, _isErc721) => (_isErc721 && desiredAmount !== -1 ? _evm : null),
      "approval.isGetApprovedEvm"
    );
    const getApproved = instance.rpc.call(
      isGetApprovedEvm,
      parsedAbi,
      proxy.new("getApproved", "approval.getApproved.functionName"),
      proxy.new([proxy.new(spender)], "approval.getApproved.args"),
      proxy.new({ name: "steps.approval.getApproved" })
    );
    const isApproved = proxy.map(
      [isErc721, al, isApprovedForAll, getApproved],
      async (_isErc721, _al, _isApprovedForAll, _getApproved) =>
        _isApprovedForAll === true ||
        // @todo check string type?
        !isNullAddr(_getApproved as string) ||
        !(
          !_al ||
          _al === BigInt(0) ||
          (desiredAmount && _al < BigInt(desiredAmount || 0))
        ),
      "approval.isApproved"
    );
    const tokenID = isErc721.map(
      (_isErc721) => (_isErc721 ? desiredAmount : null),
      "approval.tokenID"
    );
    const symbol = instance._getSymbol(evmp);
    return proxy.map(
      [isErc721, evmp, tokenID, isApproved, al, symbol],
      (_isErc721, _emvp, _tokenID, _isApproved, _al, _symbol) =>
        _isErc721
          ? {
              ...step,
              // @todo check type?
              xm: { ...step.xm, [def ? (def as string) : "spender"]: spender },
              sty: OKWidgetApproveStep,
              // label: _symbol,
              tokenID: _tokenID,

              done: _isApproved,
              def
            }
          : ({
              ...step,
              // @todo check type?
              xm: { ...step.xm, [def ? (def as string) : "spender"]: spender },
              sty: OKWidgetApproveStep,
              // label: _symbol,
              amount: BigInt(desiredAmount || 0),
              done: _isApproved,
              allowance: _al,
              def
            } as Step<OKWidgetStepType>),
      "approval.res"
    );
  };

export type TXRequest = TransactionRequest & { gasLimit: `0x${string}` };

// @todo check value < balance here?
export const generateTX = (
  rpc: LocalRPCSubscriber,
  proxy: SheetProxy,
  core: OKCore,
  contractAddr: AnyCell<ChainAddress>,
  data: AnyCell<`0x${string}`>,
  value: AnyCell<Rational>,
  enableGasEstimation = true
) => {
  const addr = contractAddr.map((_cAddr) => _cAddr.addr) as AnyCell<
    Address<Network>
  >;
  const ch = contractAddr.map((_cAddr) => _cAddr.chain) as AnyCell<ChainType>;
  const txParams = proxy.map(
    [core.WalletID, addr, data, value],
    (_walletID, _addr, _data, _value) => ({
      from: _walletID?.toString(),
      to: _addr.toString(),
      data: _data,
      value: _value.toBigInt() === 0n ? undefined : toHex(_value.toBigInt()) // rpc is expecting hex instead of bigint (viem type are oudated)
    }),
    "generateTX.txParams"
  );

  if (!enableGasEstimation) return txParams.map((_tx) => ({ tx: _tx }));
  const gas = estimateGas(
    proxy,
    rpc,
    ch,
    // @todo check type
    txParams as unknown as AnyCell<TransactionRequest>
  );

  // get the TX after estimating the gas
  const tx = proxy.map(
    [gas, txParams],
    (_gas, _txParams) => {
      if (_gas === null) return null;
      const tx = {
        tx: {
          ..._txParams,
          gasLimit: toHex((_gas * BigInt(11)) / BigInt(10))
        },
        gas_amount: _gas
      };
      return tx;
    },
    "generateTX.tx"
  );

  return tx;
};

export const generate_ntx_query = async (
  env: Environment,
  step: Step<OKWidgetStepType>
): Promise<NetworkTXQueryType> => {
  const tx = await env.evaluateString(step.ntx);
  const v = await tx.get();
  // await resolveExpr(env, step.ntx);
  if (!v || v instanceof Error || v === step?.ntx) return null;
  return v as NetworkTXQueryType;
};

/**
 * getOrgName returns org name
 * @param core
 * @param currentStep
 * @returns
 */
export const getOrgName = async (
  core: OKCore,
  env: Environment,
  query: CacheQuery,
  sty: OKWidgetStepType,
  org?: string
): Promise<string | AnyCell<string>> => {
  if (org) return env.evaluateString(org) as Promise<AnyCell<string>>;
  // resolveExpr(env, org);
  if (sty === OKWidgetSigStep || !query) return "okcontract";
  let q = query;
  if (isToken(query)) {
    const org = await core.CacheOnce(query);
    if (org?.org) q = org.org;
  }

  const queryType = type_of_cache_query(q);
  const [pfx, name] =
    q?.replace(`${prefixFromType(queryType)}`, "").split("/") || "unknown";
  if (pfx.startsWith("@")) return name;
  return pfx;
};
