<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import {
    SearchQuery,
    isContract,
    isToken,
    type CacheQuery,
    type OKToken,
    type SmartContract
  } from "@okcontract/coredata";
  import { isStringAddress, type ChainType } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { DataCacheSnippet } from "@scv/dcuic";

  import SearchSelector from "./SearchSelector.svelte";

  // optional one click address.
  export let oneClick: [string, string] | undefined = undefined;
  // optional additional search terms
  export let search_constant: string = "";
  export let placeholder: string | undefined = undefined;
  export let removable: boolean = false;
  export let disabled = false;
  export let instance = newOKPage("SearchAddress");
  const core = instance.core;
  const wantedChain = instance.wantedChain;

  /**
   * available_on_chain returns the CacheQuery if it is available on
   * current chain, or undefined otherwise.
   * @param q
   */
  const availableOnChain = async (q: CacheQuery, wanted: ChainType) =>
    isContract(q)
      ? ((await core.CacheOnce(q)) as SmartContract)?.addr.find(
          (addr) => addr.chain === wanted && q
        ) || undefined
      : (((await core.CacheOnce(q)) as OKToken)?.addr.find(
          (addr) => addr.chain === wanted
        ) !== undefined &&
          q) ||
        undefined;

  /**
   * searchFn searches for addresses.
   * @param q
   * @todo autocomplete addresses (also include users?)
   */
  const searchFn = instance.proxy.map(
    [wantedChain],
    (wanted) => async (q: string) =>
      isStringAddress(q)
        ? [q]
        : wanted
          ? (
              await Promise.all(
                (
                  (await core.CacheOnce(SearchQuery(q + search_constant)))
                    ?.res || []
                )
                  .filter((x) => isContract(x) || isToken(x))
                  .map((x) => availableOnChain(x, wanted))
              )
            ).filter((x) => !!x)
          : [],
    "SA:fn"
  );

  const dispatch = createEventDispatcher();
  const onSelect = async (ev: CustomEvent) => {
    const component = ev.detail.component;
    const selected = ev.detail.selected;
    let v = await instance
      ._retrieveAddress(wantedChain, instance.proxy.new(selected))
      // @todo remove .get()
      ?.get();
    // console.log("onSelect", { v, component, selected });
    // if (!v) v = selected;
    component.selected.set(selected);
    dispatch("update", v);
  };
</script>

<!-- @todo display in Selector -->
<!-- {$wantedChain} -->
<SearchSelector
  {disabled}
  {searchFn}
  {oneClick}
  {removable}
  {placeholder}
  inputStyle="bordered"
  on:select={onSelect}
  let:value
>
  <DataCacheSnippet dq={value} small={true} />
</SearchSelector>
