import type { AnyCell, MapCell, SheetProxy } from "@okcontract/cells";
import {
  type AnyTokenQuery,
  type ContractQueryType,
  type OKToken,
  VirtualQuery,
  isToken,
  paramsFromContractQuery,
  tokenID
} from "@okcontract/coredata";
import type {
  Address,
  ChainAddress,
  ChainType,
  Network
} from "@okcontract/multichain";

/**
 * token_address returns the address for the token on current chain.
 * @param ch the chain
 * @param tok the OKToken
 * @param px to return px if found
 */
export const token_address = (
  proxy: SheetProxy,
  ch: AnyCell<ChainType>,
  tok: AnyCell<OKToken>,
  px = false
): MapCell<Address<Network> | null, false> =>
  proxy.map([ch, tok], (_ch, _tok) => {
    if (!_tok) return null;
    const token = _tok?.addr?.find((addr) => addr.chain === _ch);
    if (!token)
      throw new Error(
        `token_address: token ${_tok.id} not found for chain ${_ch}`
      );
    return (!token?.px?.isNull() && px ? token?.px : token?.addr) || null;
  });

/**
 * get_token_on_chain returns the OKToken for a given chain
 * @param ch
 * @param tok
 * @returns
 */
export const get_token_on_chain = (ch: ChainType, tok: OKToken) =>
  tok?.addr?.find((addr) => addr.chain === ch);

/**
 * token_evm_address returns the address for the token on the desired chain as ChainAddress.
 * @param ch
 * @param token
 * @returns
 */
export const token_evm_address = (
  proxy: SheetProxy,
  ch: AnyCell<ChainType>,
  token: AnyCell<OKToken>
): MapCell<ChainAddress | null, false> => {
  const addr = token_address(proxy, ch, token, true);
  return proxy.map([addr, ch], (_addr, _ch) => {
    if (!_addr) return null;
    return {
      addr: _addr,
      chain: _ch,
      ty: "c"
    } as ChainAddress;
  });
};

// @todo migrate node to support query directly to
// 1. support anon contracts and
// 2. merge with token?
export const ABIUpdateContractQuery = (org: string, id: string) =>
  VirtualQuery("abup", `${org}/${id}`);

export const ABIUpdateTokenQuery = (q: AnyTokenQuery) =>
  VirtualQuery("aupt", q);

const updateContractQuery = (query: ContractQueryType) => {
  const { org, id } = paramsFromContractQuery(query);
  return ABIUpdateContractQuery(org, id);
};

// @todo Anon contracts
export const ABIUpdateQuery = (query: ContractQueryType | AnyTokenQuery) =>
  isToken(query)
    ? ABIUpdateTokenQuery(tokenID(query) as AnyTokenQuery)
    : updateContractQuery(query);
