<script lang="ts">
  import type { OrgQueryType } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme, Icon, Tooltip } from "@okcontract/uic";
  import { Logo, Upvote } from "@scv/dcuic";

  export let instance = newOKPage("OrgSnippet");
  export let q: OrgQueryType;
  export let verified: boolean = false;
  export let small: boolean = false;
  export let upvote: boolean = false;
  export let theme = getTheme();
  const compiledTheme = theme?.compiled;

  const core = instance.core;
  const local = instance.local;
  const proxy = instance.proxy;

  const qCell = proxy.new<OrgQueryType>(q, "OrgSnippet.qCell");
  $: qCell.set(q);

  const org = local.unwrappedCell(qCell, "org");
</script>

<div
  class="flex {small
    ? 'gap-1'
    : 'gap-2'} items-center w-full justify-between overflow-hidden"
>
  {#if !($org instanceof Error)}
    <div class="flex-none">
      <Logo
        logo={$org?.logo || $org?.id}
        size={small ? "sm" : "md"}
        remote={!core.options?.lic}
      />
      <!-- @todo no deps -->
    </div>
    <div
      class="min-w-0 grow truncate {small ? 'text-sm' : 'text-md font-semibold'}
        {theme.dark($compiledTheme, 'text-white', 'text-black', '')}"
    >
      {$org?.name || q.replace("org:", "")}
      <slot name="tag" />
    </div>
    <div class="flex justify-end shrink-0 gap-2 items-center">
      {#if verified}
        {#if $org?.cons?.length > 0}
          <Tooltip label={`${$org.cons.length} verified contracts`}>
            <div class="flex gap-1 items-center text-xs text-success">
              <span>{$org.cons.length}</span>
              <Icon name="badge-check-o" size="sm" />
            </div>
          </Tooltip>
        {/if}
      {/if}
      {#if upvote}
        <Upvote {q} style="sm" />
      {/if}
    </div>
  {/if}
</div>
