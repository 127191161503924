<script lang="ts">
  import type { PersonQueryType } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { AvatarAnon, Logo, Upvote } from "@scv/dcuic";

  export let instance = newOKPage("PersonSnippet");
  export let q: PersonQueryType;
  export let small: boolean = false;
  export let upvote: boolean = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const proxy = instance.proxy;
  const local = instance.local;

  const qCell = proxy.new(q, "PersonSnippet.qCell");
  $: qCell.set(q);

  const person = local.unwrappedCell(qCell, "person");
</script>

<div class="flex flex-row items-center justify-between">
  <div class="flex items-center">
    <div
      class="{small ? 'w-4' : 'w-6'} {theme.dark(
        $compiledTheme,
        'text-white',
        'text-black',
        'text-base-content'
      )}"
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      {#if !($person instanceof Error) && $person.pfp}
        <Logo logo={$person.pfp} size={small ? "sm" : "md"} tooltip={false} />
      {:else}
        <AvatarAnon
          username={($person && $person.name) || q.replace("pp:", "")}
          size={small ? "xs" : "sm"}
        />
      {/if}
    </div>
    <div
      class="ml-3 flex-1 cursor-pointer {small
        ? 'text-sm'
        : 'text-base font-semibold'}
        {theme.dark(
        $compiledTheme,
        'text-white',
        'text-black',
        'text-base-content'
      )}"
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      {($person && $person.name) || q.replace("pp:", "")}
      <slot name="tag" />
    </div>
  </div>
  {#if upvote}
    <Upvote {q} style="sm" />
  {/if}
</div>
