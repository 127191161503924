<script lang="ts">
  import {
    UpvoteQuery,
    UpvotesQuery,
    type CacheQueryUpVotable,
    type CachedData
  } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Icon, ThemeAccent, getTheme, type IconName } from "@okcontract/uic";
  import { delete_data, getNoWallet } from "@scv/app";

  export let q: CacheQueryUpVotable;

  /**
   * customizable action names and icon.
   */
  export let action: [string, string, string, IconName] = [
    "Watch",
    "Watching",
    "watch",
    "star"
  ];
  export let style: "sm" | "md" = "md";
  export let instance = newOKPage("Upvote");

  const showNoWallet = getNoWallet();

  const core = instance.core;
  const OUI = core.UserID;
  const conn = core.Connector;
  const account = core.ConnectorAccount;
  const local = instance.local;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const qCell = proxy.new(q, "Upvote.qCell");
  $: qCell.set(q);

  // Did the user upvote
  const qUpVoted = proxy.mapNoPrevious([OUI, qCell], (id, q) =>
    UpvoteQuery(id, q)
  );
  const upVoted = local.unwrappedCell(qUpVoted, "upVoted");
  // Total upvotes
  const qUpvotes = qCell.map((_q) => UpvotesQuery(_q));
  const upVotes = local.unwrappedCell(qUpvotes, "upVotes");

  // $: console.log({ $upVotes, $upVoted });

  const updateUpvotes = (delta: 1 | -1) => async (cd: CachedData<"upvote">) => {
    const now = await core.Cache.now();
    core.Cache.addOrUpdate({
      ty: "upvotes",
      q: UpvotesQuery(q),
      data: ($upVotes instanceof Error ? 0 : +$upVotes || 0) + delta,
      at: Date.now() / 1000,
      exp: now + 60 * 1000
    } as CachedData<"upvotes">);
  };

  const clickFn = async () => {
    if ($conn === null) {
      showNoWallet.set(true);
      // alert("Please install a wallet");
      return;
    }
    if ($account === null) {
      await core.Connect();
      await account.consolidatedValue;
    }
    const up = await upVoted.consolidatedValue;
    const id = await OUI.consolidatedValue;
    if (up instanceof Error) throw up;
    if (id instanceof Error) throw id;
    if (up) {
      // @todo core.Delete
      await delete_data(core, "upvote", UpvoteQuery(id, q), updateUpvotes(-1));
      return;
    }
    // @todo rename addr?
    const data = { addr: id, for: q };
    return core.Write("upvote", data, { callback: updateUpvotes(+1) });
  };
</script>

{#if !($upVotes instanceof Error)}
  <div class="tooltip">
    <button
      on:click|stopPropagation={clickFn}
      disabled={!$OUI}
      class="btn {style === 'sm' ? 'btn-sm' : 'btn-md join-item'} {theme.dark(
        $compiledTheme,
        'btn-white-alpha',
        'btn-black-alpha',
        'btn-default'
      )} {!$OUI ? 'opacity-50' : 'opacity-100'}"
      title={!$OUI
        ? `Connect wallet to add to your ${action[2]}`
        : `${$upVotes ? "Remove from" : "Add to"} ${action[2]}`}
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      <span
        class={$upVoted ? "text-warning" : `text-base-content`}
        style={theme.apply($compiledTheme, [ThemeAccent])}
      >
        <Icon
          name={$upVoted ? action[3] : `${action[3]}-o`}
          size={style === "sm" ? "xs" : "sm"}
        /></span
      >
      <span class="{style === 'sm' ? 'hidden sm:block' : ''} "
        >{$upVoted ? action[1] : action[0]}</span
      >
      {#if $upVotes > 0}
        <div
          class="badge badge-accent"
          style={theme.apply($compiledTheme, [ThemeAccent])}
        >
          {$upVotes ? $upVotes + " " : "0"}
        </div>
      {/if}
    </button>
  </div>
{/if}
