import type { Abi } from "viem";

import type { AnyCell } from "@okcontract/cells";
import type { Rational } from "@okcontract/lambdascript";
import type {
  Address,
  ChainAddress,
  ChainType,
  Network
} from "@okcontract/multichain";

import type { ABIExtraQueryType } from "./coredata";
import type { Organization } from "./org";
import type { OKToken, TokenItem, TokenQueryType } from "./token";
import type { OUI } from "./user";

/**
 * Raw stored ThemeDefinition.
 * @copy from "@okcontract/uic"
 */
export const ThemeText = "tx";
export const ThemeButton = "bt";
export const ThemeBackground = "bg";
export const ThemeAccent = "act";
export const ThemeDark = "dark";
export type RawThemeDefinition = {
  [ThemeAccent]?: string;
  [ThemeBackground]: string;
  [ThemeButton]?: string;
  [ThemeText]?: string;
  dark: boolean;
};

export const TokenCurrentChain = "token-chain";

/**
 * TokenMarketData is data about tokens as retrieved from the server cache.
 */
export type TokenMarketData = TokenItem & {
  image?: string;
  current_price?: Rational;
  price_change_24h?: Rational;
  market_cap?: Rational;
  total_volume?: Rational;
  total_supply?: Rational;
  status: string;
};

/**
 * GasPriceData contains information about gas price.
 */
export interface GasPriceData {
  /** chain */
  ch: string;
  FastGasPrice: string;
  LastBlock: string;
  ProposeGasPrice: string;
  SafeGasPrice: string;
  gasUsedRatio: string;
  suggestBaseFee: string;
}

/**
 * BalanceData is a data containing a balance.
 * @todo Multiple tokens?
 */
export interface BalanceData {
  /** chain */
  ch: string;
  /** token ID */
  tok: string;
  /** address */
  addr: string;
  /** amount from *big.Int: FIXME */
  amount: number;
}

/**
 * ComplexProtocol is used for data retrieved from debank.
 */
export interface ComplexProtocol {
  id: string;
  chain: ChainType;
  name: string;
  site_url: string;
  logo_url: string;
  has_supported_portfolio: boolean;
  tvl: number;
  portfolio_item_list: PortfolioItem[];
}

/**
 * PortfolioItem holds data retrieved from debank.
 */
export interface PortfolioItem {
  update_at: number;
  name: string;
  detail_types: string[];
  detail: {
    supply_token_list: TokenInfo[];
    reward_token_list?: TokenInfo[];
    description?: string;
  };
  stats: TokenStats;
  proxy_detail?: unknown;
}

/**
 * TokenInfo holds data retrieved from debank.
 */
export interface TokenInfo {
  id: string;
  chain: ChainType;
  name: string;
  symbol: string;
  display_symbol?: string;
  optimized_symbol: string;
  decimals: number;
  logo_url: string;
  protocol_id: string;
  price: number;
  is_verified?: boolean | 0 | 1;
  is_core?: boolean | 0 | 1;
  is_wallet: boolean;
  time_at?: number;
  amount: number;
  raw_amount?: number;
}

export interface TokenStats {
  asset_usd_value: number;
  debt_usd_value: number;
  net_usd_value: number;
  daily_yield_usd_value: number;
  daily_cost_usd_value: number;
  daily_net_yield_usd_value: number;
}

/**
 * Pivot definition.
 */
export interface Pivot {
  /** pivot name (or entity) */
  name: string;
  /** pivot value */
  val: string;
  /** name of value containing the range (assuming the range is a number) */
  range: string;
  /** name of value containing the address of label of a given pivot value */
  label: string;
}

export type MessageItem =
  | `.${string}` // env value
  | `@${string}` // input value
  | string; // constant value

/**
 * ABIValues defines a map of method / ABIValue
 * @todo make sure we follow `datacache` types
 */
export interface ABIValues {
  [method: string]: ABIValue;
}

export const ALL_CHAINS = "ALL";

export const ABIValueTypes = [
  "string",
  "address",
  "number",
  "token",
  TokenCurrentChain,
  "contract",
  "nft",
  "swap",
  "stake",
  "image",
  "tuple",
  "bool",
  "bytes",
  "enum",
  ALL_CHAINS
] as const;

export const ABINumber = [
  "uint",
  "uint8",
  "uint16",
  "uint24",
  "uint32",
  "uint64",
  "uint128",
  "uint256",
  "int",
  "int8",
  "int16",
  "int24",
  "int32",
  "int64",
  "int128",
  "int256"
] as const;

export type ABINumbers = (typeof ABINumber)[number];
export type ABIValueType = (typeof ABIValueTypes)[number] | ABINumbers;

// // @todo unused
// export const ABIValueTypeToMonotype = (ty: ABIValueType) => {
//   switch (ty) {
//     case "bytes":
//     case "string":
//     case "image":
//     case "enum":
//       return typeString;
//     case "bool":
//       return typeBoolean;
//     case "number":
//     case "int":
//     case "int8":
//     case "int16":
//     case "int24":
//     case "int32":
//     case "int64":
//     case "int128":
//     case "int256":
//     case "uint":
//     case "uint8":
//     case "uint16":
//     case "uint24":
//     case "uint32":
//     case "uint64":
//     case "uint128":
//     case "uint256":
//       return typeNumber;
//     case "address":
//     case "token":
//     case TokenCurrentChain:
//     case "nft":
//     case "contract":
//     case "stake":
//     case "swap":
//       return typeAddress;
//     case "tuple":
//       throw new Error("tuple not supported");
//     default:
//       throw new Error(`${ty} not supported`);
//   }
// };

export const isABINumber = (type: ABIValueType) =>
  [...ABINumber, "number"].find((n) => n === type);

/**
 * ABIValue defines how to evaluate parameters for method calls
 */
export interface ABIValue {
  /** type information */
  ty?: ABIValueType;
  /** name of value or function in env */
  v?: string;
  /** amount definition (λscript expression) */
  a?: string;
  /** default */
  def?: string;
  /** label */
  l?: string;
  /** enum */
  em?: string;
  /** full description */
  desc?: string;
  /** placeholder */
  pl?: string;
  /** min value (λscript expression) */
  min?: string;
  /** max value (λscript expression) */
  max?: string;
  /** external definition allowed */
  x?: boolean;
  /** optional */
  opt?: boolean;
  /** decimals */
  dec?: boolean;
  /** optional cell */
  cell?: AnyCell<unknown>;
}

export const ABIMethodTitle = "@t";
export const ABIMethodInfo = "@i";
export const ABIMethodButton = "@b";
export const ABIMethodImage = "@img";
export const ABIMethodAllowances = "@a";
export const ABIMethodRcpt = "@rcpt";
export const ABIMethodPre = "@pre";
export const ABIMethodAdm = "@adm";
export const ABIMethodIntent = "int";
export const ABIMethodAfter = "@aft";

/**
 * ABIMethodFields defines ABIMethod specific fields
 */
export interface ABIMethodFields {
  /** method title */
  [ABIMethodTitle]?: string[];
  /** method information text */
  [ABIMethodInfo]?: string[];
  /** button name */
  [ABIMethodButton]?: string[];
  /** image URL (possibly ipfs://, possible expression) */
  [ABIMethodImage]?: string;
  /** list of approvals lambdascript expressions for keys and values) */
  [ABIMethodAllowances]?: Record<string, string>;
  /** list of funds/tokens recipients (e.g. "to") */
  [ABIMethodRcpt]?: string[];
  /** pre-conditions (λscript expressions) */
  [ABIMethodPre]?: string[];
  /** is reserved for smart contract admin @todo extend to multiple roles? */
  [ABIMethodAdm]?: boolean;
  /** after step environment updates (each value is a lambdascript expression) */
  [ABIMethodAfter]?: Record<string, string>;
}

export const ABIMethodKeys = [
  ABIMethodTitle,
  ABIMethodInfo,
  ABIMethodButton,
  ABIMethodImage,
  ABIMethodAllowances,
  ABIMethodRcpt,
  ABIMethodPre,
  ABIMethodAdm,
  ABIMethodIntent
] as const;

export type ABIMethodKey = (typeof ABIMethodKeys)[number];
export const isABIMethodKey = (key: string): key is ABIMethodKey =>
  ABIMethodKeys.includes(key as ABIMethodKey);

/**
 * ABIMethod
 */
export type ABIMethod = ABIMethodFields & ABIValues;

export type ABIMethodType<Key extends string> =
  Key extends (typeof ABIMethodKeys)[number] ? ABIMethodFields[Key] : ABIValue;

/**
 * ABIExtra defines additional metadata for an ABI.
 */
export interface ABIExtra {
  /** identifier */
  id: string;
  /** name */
  name: string;
  /** description */
  desc?: string;
  /** pivot definition */
  pivot?: Pivot;
  /** error definitions (use ABIError) */
  err?: ABIError[];
  /** abi values */
  values?: ABIValues;
  /** method definitions */
  methods?: { [key: string]: ABIMethod };
  /** submitter address @todo remove optional once old datacache values are migrated */
  from?: string;
}

/**
 * ABIError are human-friendly error definitions.
 * @todo Use...
 */
export type ABIError = {
  /** pattern matched */
  p: string;
  /** should be ignored (default: false) */
  i?: boolean;
  /** human friendly message */
  h: string;
  /** type: warning (w), error (e), etc. */
  t?: string;
  /** actions if error (buttons, etc.) */
  ac?: string[];
};

/**
 * SmartContract holds data retrieved from DataCache
 */
export interface SmartContract {
  /**
   * identifier for a given org: org/id
   * @todo versions
   */
  id?:
    | `${string}/${string}`
    | `${string}/${string}_v${string}`
    | `${string}/${string}_v${string}_${ChainType}`;
  /** display name */
  name?: string;
  /** submitter address */
  from: OUI;
  /** fully qualified address */
  addr: ChainAddress[];
  /** is the contract active */
  act: boolean;
  /** longer description */
  desc?: string;
  /** source code repository (in Solidity) */
  src?: string;
  /** deprecated - ABI definition */
  // abi?: ABI;
  /** the token query type if it exists */
  tok?: TokenQueryType;
  /** URI of the official doc */
  doc?: string;
  /** deprecated - ABIExtra definitions */
  // x?: ABIExtra;
  /** pointers to ABIExtra references */
  xr?: ABIExtraQueryType[];
}

export type ERCType = "20" | "223" | "721" | "777" | "1155";

/**
 * ABI data definition.
 * @todo ABI<Network>?
 */
export interface ABI {
  /** contract address */
  addr: ChainAddress<Network>;
  /**
   * ABI as string
   * @todo rename to json (back...)
   */
  abi: string;
  /** optional error */
  err?: string;
  /** ABI parsed */
  parsed?: Abi;
  /** Human ABI */
  human?: string[];
  /** Unknown Signatures */
  unk?: string[];
  /** Submitter */
  from?: Address<Network>;
  /** ERC Types */
  erc?: ERCType[];
}

/**
 * LlamaProtocol holds data from DefiLlama
 */
export interface LlamaProtocol {
  id: string;
  name: string;
  address: string;
  symbol: string;
  url: string;
  description: string;
  chain: string;
  logo: string;
  audits?: string; // numbers
  audit_note?: string;
  gecko_id?: string;
  cmcId?: string;
  category?: string;
  chains?: string[];
  module?: string;
  twitter?: string;
  audit_links?: string[];
  forkedFrom?: string[];
  listedAt?: number; // date
  oracles?: string[];
  language?: string;
  slug: string;
  tvl?: number;
  chainTvls?: { [key: string]: number }; // cspell:disable-line
  change_1h?: string;
  change_1d?: string;
  change_7d?: string;
  staking?: string;
  pool2?: string;
  fdv?: string;
  mcap?: string; // cspell:disable-line
}

/** Person holds person data */
export interface Person {
  name: string;
  /** account address (or could be username?) */
  acc: string;
  pfp?: string;
  bio?: string;
  chans?: string[];
  /** orgs that the person belongs to */
  orgs: string[];
  /** orgs that the person invested in */
  invest?: string[];
  /** since */
  sce?: number;
  /** badges */
  bdg?: string[];
}

/** data_chunk is used to create an organization and its tokens and contracts from ui */
export interface data_chunk {
  organization: Organization;
  tokens: OKToken[];
  contracts: SmartContract[];
}

/** IPFSUpload is an uploaded resource to IPFS */
export interface IPFSUpload {
  wid: string; // widget ID
  addr: string; // signer
  cid: string; // CID (enforce v1?)
  seq?: number; // sequence
}

/**
 * OKURL is a whitelisted OK URL.
 */
export interface OKURL {
  id: string; // URL ID
  v: string; // URL value
}

/**
 * AccountName is the name of a user account.
 */
export interface AccountName {
  /** name */
  n: string;
  /** source of the name */
  src: "users" | "ab" | "none";
}
