import type { AnyCell, SheetProxy } from "@okcontract/cells";
import { type LabelledTypeDefinition, defaultPatterns } from "@okcontract/fred";
import { ipfs_rewrite_old } from "@okcontract/sdk";
import { remove_quotes } from "@scv/utils";
import type { ModeType } from "../types";

/**
 * canBeString check if expression can be a string for AdvancedInputEditor
 * @param str
 * @returns
 */
export const canBeString = (str: string) =>
  str === "" ||
  !str ||
  defaultPatterns.string.test(str) ||
  defaultPatterns.strictURL.test(str) ||
  str.startsWith("ipfs://");

/**
 * stringValue returns a formatted string
 * (check for string expression / URL / IPFS url)
 * @param str
 */
export const stringValue = (str: string) => {
  try {
    if (str?.startsWith('"')) return JSON.parse(str);
    if (str?.startsWith("ipfs://") || defaultPatterns.strictURL.test(str))
      return str;
  } catch (_err) {}
  return str;
};

/**
 * imageURL returns a readable url either from IPFS or https
 * @param input
 * @returns
 */
export const imageURL = (input: string): string | undefined =>
  typeof input === "string"
    ? input?.includes("ipfs://")
      ? ipfs_rewrite_old(remove_quotes(input))
      : input?.includes("https://")
        ? remove_quotes(input)
        : undefined
    : undefined;

export const canBeImage = (input: string | undefined) =>
  !input || input === "" || imageURL(input) !== undefined;
// defaultPatterns.string.test((await value.get()) as string)

/**
 * initialMode computes the initial mode value.
 */
export const initialMode = (
  proxy: SheetProxy,
  definition: AnyCell<LabelledTypeDefinition>,
  value: AnyCell<unknown>
): AnyCell<ModeType> => {
  return proxy.map([definition, value], (def, value) => {
    if ("isImg" in def && def.isImg && canBeImage(value as string))
      return "img";
    if ("search" in def && def.search) return "coredata";
    if ("isColor" in def && def.isColor) return "color";
    if (
      "isExpr" in def &&
      def.isExpr &&
      !defaultPatterns.string.test(value as string)
    )
      return "expr"; // @todo unless value is simple string
    return "string";
  });
};
