<script lang="ts">
  import * as Config from "../../config.json";
  const DEV = Config.dev;

  import type { AnyCell } from "@okcontract/cells";
  import { Card, Icon } from "@okcontract/uic";

  export let cell: AnyCell<unknown>;
  export let where: string = undefined;

  const rewrite = (msg: unknown) =>
    msg
      .toString()
      .replaceAll("Error: ", "")
      .replace("no response", "no RPC response");

  const rePrefix = (prefix: string) => new RegExp(`^${prefix}`);

  const rewriteMsg = (msg: unknown) =>
    rewrite(msg)
      .replaceAll("has an error: ", "")
      .replace(rePrefix("Cell "), "");

  let isOpen = false;
</script>

{#if $cell instanceof Error}
  <Card style="default" pad="sm">
    <div class="flex gap-2 items-center break-all text-error">
      <Icon name="info-o" size="md" />
      <span class="flex flex-col items-start flex-wrap break-words">
        {#if DEV && where}
          <div class="badge">{where}</div>
        {/if}
        {#if "reason" in $cell && "source" in $cell}
          <div class="flex gap-2 items-center">
            {#if DEV}
              <b>Cell {$cell.source}</b>:
            {/if}
            {rewrite($cell.reason)}
            {#if "message" in $cell}
              <button
                class="flex items-center btn btn-xs btn-ghost btn-circle"
                title={`Details`}
                on:click={() => {
                  isOpen = !isOpen;
                }}
              >
                <Icon name="chevron-d" size="xs" opacity="70" />
              </button>
            {/if}
          </div>

          {#if "message" in $cell}
            <div class="flex gap-2 items-center break-all">
              {#if isOpen}
                <div class="z-20 flex gap-1 items-center text-sm">
                  {rewriteMsg($cell.message)}
                </div>
              {/if}
            </div>
          {/if}
        {:else}
          <b>Cell {cell.id}</b>: {rewrite($cell.message)}
        {/if}
      </span>
    </div>
  </Card>
{/if}
