<script lang="ts">
  import type { Stepper } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Loading, getTheme } from "@okcontract/uic";
  import { AlertCellError, Image, OrgSnippet, Success } from "@scv/dcuic";

  import ABIFunction from "./ABIFunction.svelte";
  import Titles from "./Titles.svelte";
  import WidgetHeader from "./WidgetHeader.svelte";
  import WidgetTxSent from "./WidgetTXSent.svelte";

  export let instance = newOKPage("WidgetTX");
  export let stepper: Stepper;
  export let hideLabels = true;
  export let switcher = true;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const okTX = stepper.current;
  const isDone = stepper.isDone;
  const isLastTX = stepper.isLastTX;
  const sentTxs = stepper.sentTXs;
  const receipts = stepper.receipts;

  // @todo do we need the reactivity?
  $: image = instance.proxy.map([okTX], (_s) => _s.image, "WidgetTX.image");
  $: org = instance.proxy.map([okTX], (_s) => _s.org, "WidgetTX.org");
</script>

{#if $okTX instanceof Error}
  <div class="p-4">
    <AlertCellError cell={okTX} where="Interaction specification" />
  </div>
{:else if $okTX}
  <WidgetHeader {instance} {theme} okTX={$okTX} {switcher} />
  <div
    class="absolute bottom-12 left-0 right-0 top-14 z-20 overflow-auto overscroll-y-contain {theme.dark(
      $compiledTheme instanceof Error ? null : $compiledTheme,
      'text-white',
      'text-black',
      'text-base-content'
    )}"
  >
    <!-- style={theme.apply($compiledTheme instanceof Error ? null : $compiledTheme, [ThemeText])} -->
    <!-- @todo receipts should be on each chain... -->
    <div class="grid gap-2 content-start w-full px-4">
      {#if $isDone && $okTX.receipt}
        <div class="mb-2 capitalize text-lg">
          <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
        </div>
        <div class="mt-4 grid gap-2 w-full">
          <Success message={"Transaction successful"} />
          <WidgetTxSent
            txs={sentTxs}
            receipt={$okTX.receipt}
            {receipts}
            chain={$okTX.chain}
            tokenPrice={$okTX.tokenPriceData}
          />
        </div>
      {:else if $isLastTX}
        <div class="mb-2 capitalize text-lg">
          <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
        </div>
        <div class="mt-4 grid gap-2 w-full">
          <div class="w-full flex justify-center my-4">
            <span class="loading loading-spinner loading-lg"></span>
          </div>
          <h4
            class="w-full flex justify-center {theme.dark(
              $compiledTheme,
              'text-white',
              'text-black',
              'text-base-content'
            )}"
          >
            Transaction in progress. Please wait...
          </h4>
        </div>
      {:else}
        {#if $image && !($image instanceof Error)}
          <div class="mx-auto my-3">
            <Image src={$image} />
          </div>
        {/if}
        <div class="mb-2 capitalize text-lg">
          <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
        </div>
        <div class="flex gap-1 flex-wrap items-center text-base font-semibold">
          <Titles
            {instance}
            item={$okTX.title}
            size="md"
            chain={$okTX.contractChain}
          />
        </div>
        <ABIFunction {instance} okTX={$okTX} {hideLabels} />
      {/if}
    </div>
  </div>
{:else}
  <div class="grid gap-2 w-full py-8">
    <Loading />
  </div>
{/if}
