<script lang="ts">
  import { DataEditor, type TypeScheme } from "@okcontract/fred";
  import {
    settingsSchema,
    settingsTypescheme,
    type OKPage
  } from "@okcontract/sdk";
  import { Dropdown, getTheme, Icon, ThemeBackground } from "@okcontract/uic";
  import { DataEditorLight } from "@scv/dataeditor";

  export let instance: OKPage;
  const proxy = instance.proxy;
  const core = instance.core;

  export let theme = getTheme();
  const compiledTheme = theme?.compiled;

  export let small = false;

  const schema = {
    types: proxy.new(settingsTypescheme(proxy)),
    values: proxy.new(settingsSchema(proxy)),
    gs: proxy.new([])
  } as TypeScheme;

  // We directly map the Environment value cells to a new proxy.
  const data = proxy.new(core.Settings.values);
  const editor = new DataEditor(proxy, data, schema);

  let buttonElement: HTMLElement;
  const open = proxy.new(false);

  // small full-screen dropdown
  let setOpen = false;
  const toggleSetOpen = () => {
    setOpen = !setOpen;
  };
</script>

{#if small}
  <button
    on:click={toggleSetOpen}
    class="flex flex-row items-center justify-center btn btn-sm btn-circle {theme.dark(
      $compiledTheme,
      'btn-white-alpha',
      'btn-black-alpha',
      'btn-ghost'
    )}"
  >
    <Icon name="cog-o" size="sm" opacity="70" />
  </button>

  {#if setOpen}
    <div
      class="absolute top-0 bottom-0 right-0 left-0 z-30 flex flex-col p-4 rounded-box {theme.dark(
        $compiledTheme,
        'bg-black text-white',
        'bg-white text-black',
        'bg-base-100 text-base-content'
      )}"
      style={theme.apply($compiledTheme, [ThemeBackground])}
    >
      <header class="mb-2 flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <Icon name="cog-o" size="sm" opacity="50" />
          <div class="text-lg font-semibold">Settings</div>
        </div>
        <button
          class="btn btn-sm btn-circle {theme.dark(
            $compiledTheme,
            'btn-white-alpha',
            'btn-black-alpha',
            'btn-ghost'
          )}"
          on:click={toggleSetOpen}
          ><Icon name={"close"} size="sm" opacity="70" /></button
        >
      </header>
      <section class="w-full h-full">
        <DataEditorLight {instance} {editor} />
      </section>
    </div>
  {/if}
{:else}
  <Dropdown
    style="bottom-end"
    {open}
    {buttonElement}
    on:close={() => open.set(false)}
  >
    <div slot="action">
      <button
        bind:this={buttonElement}
        on:click={() => open.update((_open) => !_open)}
        class="flex flex-row items-center justify-center btn btn-xs rounded-full btn-ghost"
      >
        <Icon name={"cog-o"} size={"xs"} opacity="70" />
      </button>
    </div>
    <div slot="main" class="min-w-[200px]">
      <DataEditorLight {instance} {editor} />
    </div>
  </Dropdown>
{/if}
