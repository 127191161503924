import type { ChainAddress } from "@okcontract/multichain";
import type { ChannelValue } from "@scv/auth";

import type { ForeignID } from "./foreign";
import type { OrgQueryType } from "./org";

export type NFTQueryType = `nft:${string}`;
export type StakeQueryType = `stake:${string}`;
export type LendQueryType = `lend:${string}`;
export type TokenQueryType = `tok:${string}`;
export type SwapQueryType = `swap:${string}`;

export type AnyTokenQuery =
  | NFTQueryType
  | StakeQueryType
  | LendQueryType
  | TokenQueryType
  | SwapQueryType;

export const TokenQuery = (id: string): TokenQueryType =>
  `tok:${id.toLowerCase()}`;
export const NFTQuery = (id: string): NFTQueryType => `nft:${id.toLowerCase()}`;

export const BasicTokenKind = "token";
export const StakeTokenKind = "stake";
export const SwapPoolTokenKind = "swap";
export const LendPoolTokenKind = "lend";
export const NFTTokenKind = "nft";

const PoolToken = [
  BasicTokenKind,
  StakeTokenKind,
  SwapPoolTokenKind,
  LendPoolTokenKind,
  NFTTokenKind
] as const;

/**
 * PoolTokenType type of the token: Basic (by default), Stake, SwapPool, LendPool.
 */
export type PoolTokenType = (typeof PoolToken)[number];

/**
 * TokenItem as sent by CoinGecko.
 */
export interface TokenItem {
  id: string;
  symbol: string;
  name: string;
}

/**
 * OKToken holds data retrieved from DataCache
 * @todo rename OKToken
 */
export type OKToken = TokenItem & {
  /** org id */
  org?: OrgQueryType;
  /** is the token active */
  act: boolean;
  /** submitter address */
  from?: string;
  /** type of the token: Basic, Stake, SwapPool, LendPool */
  ty?: PoolTokenType;
  /**
   * underlying tokens for pools (1..n)
   * @todo should be a query?
   **/
  toks?: string[];
  addr: ChainAddress[];
  /** foreign ids for the token */
  foreign?: ForeignID[];
  /** decimals */
  decimals?: number;
  url?: string;
  logo?: string;
  /** description for NFT */
  desc?: string;
  /** list of communication channels */
  chans?: ChannelValue[];
};

/**
 * isToken returns true if the Query is a token.
 *
 * @description This function does not resolve tokens from their address.
 * @param q query
 */
export const isToken = (q: unknown): q is AnyTokenQuery =>
  typeof q === "string" &&
  (q.startsWith("tok:") ||
    q.startsWith("nft:") ||
    q.startsWith("stake:") ||
    q.startsWith("swap:") ||
    q.startsWith("lend:"));

/**
 * toToken formats any supposed string to a token query.
 * This function preserves null and undefined values.
 * @param q query
 */
export const toToken = (q: unknown) =>
  isToken(q) ? q : typeof q === "string" ? `tok:${q.toLowerCase()}` : q;

/**
 * tokenID extracts the ID from a Token query, discarding the prefix.
 */
export const tokenID = (q: AnyTokenQuery) => q.substring(q.indexOf(":") + 1);
