// @todo move to new file depending on Network
import type { TransactionReceipt } from "viem";

import {
  type AnyCell,
  type Cellified,
  type MapCell,
  type SheetProxy,
  cellify,
  findIndex,
  mapArray,
  reduce
} from "@okcontract/cells";
import {
  AnonContractQuery,
  type OKWidgetStep,
  type OKWidgetStepType
} from "@okcontract/coredata";
import {
  type Environment,
  type Rational,
  type ValueDefinition,
  newTypeScheme,
  typeNumber
} from "@okcontract/lambdascript";
import {
  type Address,
  type ChainAddress,
  type ChainType,
  ContractType,
  EstimateGasQuery,
  type GetEstimateGas,
  type Network,
  ethCall,
  ethCallQuery
} from "@okcontract/multichain";
import {
  convertToAddressesAndRationals,
  convertToNativeAddressesAndBigInt
} from "@scv/cache";

import { isKnownAddress } from "./address";
import type { OKCore } from "./coreExecution";
import { erc20AbiEVM } from "./erc20";
import type { OKPage } from "./instance";
import {
  EnvKeyAllowanceSpender,
  EnvKeyCurrentAllowance,
  EnvKeyWantedAllowance
} from "./keys";
import { typeAddress } from "./lambdascript";
import { type AnyContractQuery, OKContractManager } from "./okcontract";
import { OKTransaction } from "./oktransaction";
import type { Step, TXRequest } from "./steps";
import type { SentTransaction } from "./tx";

type FlattenedStep = {
  okTX: OKTransaction<AnyContractQuery>;
  idx: [number, number];
};

export class Stepper {
  private _instance: OKPage;
  private core: OKCore;
  private proxy: SheetProxy;
  private allowance: AnyCell<string>; // constant
  private _okc: OKContractManager;

  /** stepper cursor (can trigger cursor changes manually) */
  // readonly wantedCursor: ValueCell<[number, number]>;
  /** cursor position in flatSteps */
  readonly doneCursor: MapCell<number, true>;
  /** stepper cursor */
  readonly cursor: AnyCell<[number, number]>;
  /** the list of OKTransaction steps */
  readonly steps: MapCell<
    MapCell<OKTransaction<AnyContractQuery>[], boolean>[],
    boolean
  >;
  /** the flatten list of OKTransaction */
  readonly flatSteps: MapCell<FlattenedStep[], boolean>;
  /** current step */
  readonly current: AnyCell<OKTransaction<AnyContractQuery>>;
  /** if a tx is currently processing */
  readonly isSending: MapCell<number, true>;
  /** check if the current step is the last  */
  readonly isLastStep: AnyCell<boolean>;
  /** check if the current sent TX is the last */
  readonly isLastTX: MapCell<boolean, true>;
  /** if steps are all done */
  readonly isDone: MapCell<boolean, boolean>;
  /** the transaction requests */
  readonly txr: MapCell<AnyCell<TXRequest>[], false>;
  /** the desired chain */
  // readonly chain: AnyCell<ChainType>;

  // @todo add SharedEnv?
  // stepper env (env shared between all steps)

  /** env of the current step */
  readonly env: MapCell<Environment, false>;
  /** all receipts */
  readonly receipts: MapCell<TransactionReceipt[][], false>;
  /** list of sent transactions */
  readonly sentTXs: MapCell<SentTransaction[][], false>;

  constructor(
    instance: OKPage,
    okSteps: Cellified<Step<OKWidgetStepType>[]>,
    options: {
      // @todo should be an Environment directly
      extraEnv?: ValueDefinition[];
      forceTXGeneration: boolean;
      okc?: OKContractManager;
    } = {
      forceTXGeneration: false
    }
  ) {
    this._instance = instance;
    // @todo use Instance directly
    this.core = instance.core;
    this.proxy = instance.proxy;
    this._okc = options?.okc || new OKContractManager(instance);

    this.allowance = this.proxy.new("allowance"); // method

    this.steps = mapArray(
      this.proxy,
      okSteps,
      (_okStep, _index, okStep) => {
        // console.log("okTX: new", _index);
        // 1. create main step transaction
        const main = new OKTransaction(
          instance,
          okStep as Cellified<Step<OKWidgetStepType>>,
          // this.chain,
          {
            settingsEnv: this.core.Settings,
            forceTXGeneration: options.forceTXGeneration,
            extraEnv: options?.extraEnv,
            okc: this._okc
          }
        );
        // 2. build approvals
        return this._approvalTxs(instance, main, _okStep?.xm, options).map(
          (l) => [...l, main]
        );
      },
      "st:steps"
    );

    // this.wantedCursor = this.proxy.new([0, 0], "st:wantedCursor");

    this.flatSteps = reduce(
      this.proxy,
      this.steps,
      (acc, steps, i) => [
        ...acc,
        ...steps.map((okTX, j) => ({ okTX, idx: [i, j] as [number, number] }))
      ],
      [] as FlattenedStep[],
      "st:flatSteps"
    );

    const donePositions = this.flatSteps.map(
      (l) => l.map((fs) => fs.okTX.isDone),
      "st:donePositions",
      true
    );

    this.txr = this.flatSteps.map(
      (l) => l.map((fs) => fs.okTX.tx.map((_r) => _r?.tx || null)),
      "st:txr"
    );

    // @todo add another ValueCell cursor to move manually between steps
    this.doneCursor = findIndex(
      this.proxy,
      donePositions,
      (done) => !done,
      "st:doneCursor",
      true
    );

    // @todo add another ValueCell cursor to move manually between steps
    this.cursor = this.proxy.mapNoPrevious(
      [this.flatSteps, this.doneCursor],
      (fs, dc) => fs[dc === -1 ? 0 : dc].idx,
      "st:cursor"
    );

    this.current = this.proxy.mapNoPrevious(
      [this.flatSteps, this.doneCursor],
      async (fs, dc) => fs?.[dc === -1 ? fs.length - 1 : dc]?.okTX || null,
      "st:current"
    );

    this.env = this.current.map((tx) => tx.env, "st:env");

    // @todo add types
    // const logEnv = instance.newEnvironment(
    //   this.proxy,
    //   instance.local,
    //   undefined,
    //   {
    //     [EnvKeyRawLogs]: this.proxy.new([], "logEnv.raw"),
    //     [EnvKeyAnalyzedLogs]: this.proxy.new([], "logEnv.analyzed"),
    //     [EnvKeyPrettyLogs]: this.proxy.new([], "logEnv.pretty"),
    //   },
    //   {
    //     [EnvKeyPrettyLogs]: {
    //       vars: [],
    //       type: {
    //         kind: "lst",
    //         elementType: {
    //           kind: "lst",
    //           elementType: { kind: "var", type: "AnalyzedLog" }, // @todo define...
    //         },
    //       },
    //     },
    //   }
    // );

    // const logs = this.proxy.new(
    //   {
    //     raw: [],
    //     analyzed: [],
    //     pretty: [],
    //   },
    //   "st:logs"
    // );
    const isSendingTxList = this.flatSteps.map(
      (l) => l.map((fs) => fs.okTX.isSending),
      "isSendingTxList",
      true
    );
    this.isSending = findIndex(
      this.proxy,
      isSendingTxList,
      (s) => s,
      "isSending.findIndex",
      true
    );
    this.isDone = reduce(this.proxy, donePositions, (l, d) => l && d, true);

    const receiptList = this.flatSteps.map(
      (l) => l.map((fs) => fs.okTX.receipts),
      "receiptList",
      true
    );
    // only existing receipts,
    this.receipts = reduce(
      this.proxy,
      receiptList,
      (l, r) => (r ? [...l, r] : l),
      [] as TransactionReceipt[][]
    );

    const sentTxsList = this.flatSteps.map(
      (l) => l.map((fs) => fs.okTX.sentTx),
      "sentTxsList",
      true
    );
    this.sentTXs = reduce(
      this.proxy,
      sentTxsList,
      (l, s) => (s?.length ? [...l, s] : l),
      [] as SentTransaction[][]
    );
    this.isLastStep = this.proxy.map(
      [donePositions, this.doneCursor],
      (dp, dc) => dc === dp.length - 1,
      "st:isLastStep"
    );
    this.isLastTX = this.proxy.map(
      [this.isLastStep, this.isSending],
      (_isLastStep, _isSending) => _isSending !== -1 && _isLastStep,
      "st:isLastTX",
      true
    );
  }

  private _approvalStep = (
    addr: AnyCell<Address<Network>>,
    chain: AnyCell<ChainType>,
    wanted: AnyCell<Rational>,
    chains: AnyCell<Map<ChainType, unknown>>
  ): Cellified<OKWidgetStep<"app">> =>
    this.proxy.map(
      [addr, chain, wanted, chains],
      async (_addr, _chain, _wanted, _chains) => {
        const query =
          (await isKnownAddress(
            this._instance.core,
            _addr.toString(),
            _chain
          )) || AnonContractQuery(_addr, _chain);

        return cellify(
          this.proxy,
          {
            sty: "app",
            m: "approve",
            q: query,
            skip: `${EnvKeyWantedAllowance} <= ${EnvKeyCurrentAllowance}`,
            fch: Array.from(_chains.keys()),
            // constant parameter names defined in erc20.ts
            xm: {
              _value: {
                v: EnvKeyWantedAllowance,
                a: _addr.toString(),
                ty: "number",
                l: "Amount"
              },
              _spender: { v: EnvKeyAllowanceSpender }
            }
          } as OKWidgetStep<"app">,
          `approve:${_chain}`
        );
      }
    ) as unknown as Cellified<OKWidgetStep<"app">>;

  private _approvalTxs = (
    instance: OKPage,
    main: OKTransaction<AnyContractQuery>, // main transaction
    xm: Cellified<unknown>, // @todo
    options: {
      chain?: AnyCell<ChainType>;
      forceTXGeneration: boolean;
    } = {
      forceTXGeneration: false
    }
  ): MapCell<OKTransaction<AnyContractQuery>[], false> => {
    const owner = this.core.WalletID;
    const mainAddr = main.okContract.map((okc) => okc.address);
    // @todo pre-compute addr in another cell
    const spender = mainAddr.map((_addr) => _addr.addr);
    const spenderOwner = this.proxy.map(
      [owner, spender],
      (_owner, _spender) => [
        convertToNativeAddressesAndBigInt(_owner),
        convertToNativeAddressesAndBigInt(_spender)
      ]
    );
    // rebuild the last estimate gas query to invalidate it
    // @todo We should be able to remove this if GetEstimateGas query
    // is not performed for both steps.
    const mainParams = instance.proxy.map(
      [owner, mainAddr, main.encodedData],
      (_walletID, _addr, _data) => ({
        from: _walletID?.toString(),
        to: _addr.addr.toString(),
        data: _data
      }),
      "generateTX.txParams"
    );

    return this.proxy.map(
      [main.allowances, main.env, main.contractChain],
      (_allowances, _env, _chain) => {
        return _allowances.map(([key, def]) => {
          const addr = _env.value(key) as AnyCell<Address<Network>>;
          const token = addr.map(
            (_addr) =>
              ({ addr: _addr, chain: _chain, ty: ContractType }) as ChainAddress
          );
          const approvalStep = this._approvalStep(
            addr,
            main.contractChain,
            def as unknown as AnyCell<Rational>,
            main.chains
          );
          const allowanceQuery = ethCallQuery(
            this.proxy,
            token,
            this.core.DefaultContracts.erc20AbiEVM,
            this.allowance,
            spenderOwner
          );
          // retrieves current allowance and wanted allowance
          const currentAllowance = ethCall(
            instance.rpc,
            allowanceQuery,
            this.core.DefaultContracts.erc20AbiEVM,
            this.allowance,
            {
              name: "currentAllowance",
              convertFromNative: convertToAddressesAndRationals
            }
          );

          // @todo we also need to inject all dependencies of the allowance computation
          const extraEnv = [
            [
              EnvKeyCurrentAllowance,
              currentAllowance,
              newTypeScheme(typeNumber)
            ],
            [EnvKeyWantedAllowance, def, newTypeScheme(typeNumber)],
            [EnvKeyAllowanceSpender, spender, newTypeScheme(typeAddress)]
          ] as ValueDefinition[];

          // @todo We should not create the transaction if it not required.
          const appTX = new OKTransaction(instance, approvalStep, {
            extraEnv,
            abi: erc20AbiEVM,
            settingsEnv: this.core.Settings,
            forceTXGeneration: options.forceTXGeneration,
            title: main.title,
            info: main.info,
            org: main.org,
            editor: main.editor,
            isPreOk: main.isPreOk,
            okc: this._okc
          });
          const estimateQuery = instance.proxy.map(
            [token, mainParams],
            // @ts-expect-error @todo authorizationList
            (_token, _params) => [_token.chain, EstimateGasQuery(_params)]
          ) as AnyCell<[string, GetEstimateGas]>;
          appTX.receipt.subscribe((_receipt) =>
            appTX._approvalRefresh(_receipt, allowanceQuery, estimateQuery)
          );
          return appTX;
        });
      }
    );
  };
}
