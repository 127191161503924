<!-- Don't forget to key around this component for reactivity e.g.
{#key image}
  <Image src={image} />
{/key}
-->
<script lang="ts">
  import { onMount } from "svelte";

  import { ipfs_rewrite_old } from "@okcontract/sdk";
  import { Toggle } from "@okcontract/uic";

  const styleMap = {
    sm: "h-20 min-w-20",
    md: "h-56 w-56",
    free: ""
  };

  /** Image source, including special "loading" value or "ipfs://" links */
  export let src: string;
  export let alt: string = "Image";
  export let style: "sm" | "md" | "free" = "md";

  let i: HTMLImageElement = new Image();
  export let loading = true;

  /**
   * Update the image source.
   * @param src
   * @todo Generalize with array of images to multiple gateways for IPFS.
   */
  const update = async (src: string) => {
    loading = true;
    if (typeof src === "string" && src !== "loading") {
      try {
        // console.log("update=", { src });
        const isIPFS = src?.startsWith("ipfs://");
        i.src = ipfs_rewrite_old(src);

        if (isIPFS) {
          let b = new Image();
          b.src = ipfs_rewrite_old(src, 1);

          const ldi = await Promise.race([
            new Promise((resolve) => (i.onload = () => resolve(false))),
            new Promise((resolve) => (b.onload = () => resolve(true)))
          ]);
          if (ldi) {
            i?.replaceWith(b);
            i?.remove();
          }
        } else {
          await new Promise((resolve) => (i.onload = () => resolve(true)));
        }
        loading = false;
      } catch (error) {
        console.error(error);
      }
    }
  };

  $: src && update(src);

  onMount(() => {
    update(src);
  });
</script>

<!-- We use Toggle as the <img> element must be displayed at all times. -->
<Toggle condition={loading}>
  <div slot="if">
    <div class="skeleton {styleMap[style]} rounded-btn"></div>
  </div>

  <div slot="else" class="grid rounded-btn">
    <img
      class="{styleMap[style]} rounded-btn object-cover"
      bind:this={i}
      {alt}
    />
  </div>
</Toggle>
