import type { AnyTokenQuery } from "@okcontract/coredata";
import type { Address, Network } from "@okcontract/multichain";

// @todo refactor coredata
export type TokenQueryType =
  | `tok:${string}`
  | `nft:${string}`
  | `swap:${string}`
  | `stake:${string}`;
export type TokenQueryOrAddress = TokenQueryType | Address<Network>;
export type ContractQueryType = `con:${string}`;
export type ContractQueryOrAddress = ContractQueryType | Address<Network>;
export type AnyAddress = ContractQueryType | AnyTokenQuery | Address<Network>;

export type URLQueryType = `url:${string}`;

export type VirtualQueryType<
  Key extends string,
  Value extends string
> = `χ${Key}:${Value}`;

export type ProxyQueryType = VirtualQueryType<"proxy", string>;
export type VirtualProxyQueryType = VirtualQueryType<"proxy.head", string>;
export const ProxyQuery = (url: string): ProxyQueryType => `χproxy:${url}`;
export const VirtualProxyQuery = (url: string): VirtualProxyQueryType =>
  `χproxy.head:${url}`;
