import {
  type ABI,
  type ABIExtra,
  ABIExtraQuery,
  type ABIExtraQueryType,
  type OKToken,
  type OKWidgetStep,
  type OKWidgetStepType,
  type SmartContract
} from "@okcontract/coredata";
import { mergeObjectList, mergeObjects } from "@scv/utils";

import type { AnyCell, SheetProxy } from "@okcontract/cells";
import type { OKCore } from "./coreExecution";
import { ERC20, abix_erc20 } from "./erc20";
import { ERC721, abix_erc721 } from "./erc721";

/**
 * abixOrId accepts an ABI reference without abix:
 * @param id
 * @returns
 * @todo: We should probably remove this and ensure we always use abix:
 */
export const abixOrId = (id: ABIExtraQueryType | string): ABIExtraQueryType =>
  id?.startsWith("abix:") ? (id as ABIExtraQueryType) : ABIExtraQuery(id);

/**
 * getABIX returns an ABIExtra
 * @param id
 * @returns
 * @todo do we want to return an empty ABIExtra if not found?
 */
export const getABIX = (core: OKCore, id: ABIExtraQueryType | string) => {
  if (id) {
    return core.CacheOnce(abixOrId(id));
  }
  return null;
};

/**
 * getABIXFromRefs builds the ABIExtra from the reference list.
 * @param xr
 * @returns
 * @todo
 */
export const getABIXFromRefs = async (
  core: OKCore,
  xr: ABIExtraQueryType[]
): Promise<ABIExtra> =>
  xr?.length
    ? {
        ...mergeObjectList(
          await Promise.all(xr.map((id) => getABIX(core, id)))
        ),
        id: "abix:@merged"
      }
    : null;

/**
 * getMergedABIX reconstructs a merged ABIx from the data (contract or token) and ABI.
 */
export const getMergedABIX = <Data extends SmartContract | OKToken>(
  core: OKCore,
  proxy: SheetProxy,
  abi: AnyCell<ABI>,
  data: AnyCell<Data>
) =>
  proxy.map(
    [abi, data],
    (abi, data) =>
      abi === null
        ? null
        : data && "xr" in data && data?.xr
          ? getABIXFromRefs(core, data.xr)
          : abi?.erc
            ? abi.erc.includes(ERC721)
              ? abix_erc721(abi)
              : abi.erc.includes(ERC20)
                ? abix_erc20(abi)
                : {
                    id: "@null",
                    name: "empty ABIX"
                  }
            : null,
    "mABIx"
  );

/**
 * mergeOKStepInABIX merges okstep ABIMethod with ABIExtra methods
 * NB: okstep overwrites any existing methods of ABIExtra
 * @param okstep
 * @param abix
 * @returns
 */
export const mergeOKStepInABIX = (
  okstep: Partial<OKWidgetStep<OKWidgetStepType>>,
  abix: ABIExtra,
  req_inputs?: string[]
) => {
  if (!okstep?.m || (!abix?.methods && !abix?.methods)) return abix;
  return {
    ...abix,
    methods: mergeObjects(abix.methods, {
      [okstep.m]: Object.fromEntries(
        Object.entries(
          mergeObjectList([okstep?.xm, abix.methods[okstep.m], abix.values])
        ).filter(([k, v]) => (req_inputs ? req_inputs.includes(k) : true))
      )
    })
  };
};
