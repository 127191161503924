<!-- @todo rename to SelectChains -->
<script lang="ts">
  import { type AnyCell } from "@okcontract/cells";
  import { ChainQuery } from "@okcontract/coredata";
  import { type ChainType } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Dropdown, Icon, getTheme } from "@okcontract/uic";
  import { AlertCellError, Logo } from "@scv/dcuic";

  import ChainButton from "./ChainButton.svelte";
  import ChainListItem from "./ChainListItem.svelte";

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  export let instance = newOKPage("SwitchChains");
  export let chains: AnyCell<ChainType[]>;

  const core = instance.core;
  const coreChains = core.Chains;
  const loading = core.IsSwitchingChain;
  const noWallet = core.IsNoWallet;
  export let current = core.Chain;
  export let displayCurrent = false;

  // Optional label
  export let label = "Switch to";
  export let size: "xs" | "sm" | "md" = "sm";
  export let small: boolean = false;

  let buttonElement: HTMLElement;
  const open = instance.proxy.new(false, "SwitchChain.dropDownOpen");
</script>

{#if $chains instanceof Error}
  <AlertCellError cell={chains} />
{:else if $coreChains instanceof Error}
  <AlertCellError cell={coreChains} />
{:else if !$chains?.length}
  <span class="loading loading-spinner loading-xs"></span>
{:else if $chains.length > 1}
  <!-- disabled={!hasChains} -->
  {#if ($loading && $noWallet !== true) || $current === undefined}
    <span class="loading loading-spinner loading-xs"></span>
  {:else if $current instanceof Error}
    <span title={$current.toString()}>Error</span>
  {:else if $current !== null}
    <Dropdown
      style={small ? "bottom" : "bottom-end"}
      size="sm"
      {open}
      {buttonElement}
      on:close={() => open.set(false)}
    >
      <div slot="action">
        <button
          bind:this={buttonElement}
          on:click={() => open.update((_open) => !_open)}
          class="flex flex-row items-center btn btn-{size} rounded-full {theme.dark(
            $compiledTheme,
            'btn-white-alpha',
            'btn-black-alpha',
            'btn-ghost'
          )}"
        >
          <Logo
            logo={$current?.logo || $current?.id}
            size="sm"
            remote={!core.options?.lic}
          />
          <span class="font-medium hidden lg:block">
            {#if displayCurrent}
              {#if $current instanceof Error}
                Error
              {:else if $current}
                {$current?.name || label}
              {:else}
                <span class="loading loading-spinner loading-xs"></span>
              {/if}
            {:else}
              {label}
            {/if}
          </span>
          <span
            class={theme.dark($compiledTheme, "text-white", "text-black", "")}
            ><Icon name="chevron-d" size="xs" opacity="80" /></span
          >
        </button>
      </div>
      <ul slot="main">
        {#each $chains as ch}
          {@const chain = $coreChains?.[ChainQuery(ch)]}
          <ChainListItem {instance} {chain} {open} {small} />
        {/each}
      </ul>
    </Dropdown>
  {/if}
{:else}
  <ChainButton {instance} chain={$chains[0]} />
{/if}
