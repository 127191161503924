// @todo remove?
export const ABIXKey = "#abix";

import type { AbiFunction } from "abitype";
import { type Abi, toFunctionSignature } from "viem";

import {
  type AnyCell,
  type Cellified,
  type SheetProxy,
  type ValueCell,
  initialValue
} from "@okcontract/cells";
import {
  type ABIExtra,
  type ABIMethod,
  ABIMethodAdm,
  ABIMethodAfter,
  ABIMethodAllowances,
  ABIMethodButton,
  type ABIMethodFields,
  ABIMethodImage,
  ABIMethodInfo,
  ABIMethodIntent,
  ABIMethodPre,
  ABIMethodRcpt,
  ABIMethodTitle,
  type ABIValueType,
  ABIValueTypes,
  type ABIValues,
  IntentChainKey,
  IntentDeposit,
  IntentSwap,
  IntentTypeKey,
  IntentTypes,
  IntentWithdraw,
  OKWidgetCallStep,
  OKWidgetNetworkTXStep,
  OKWidgetSigStep,
  type OKWidgetStep,
  type OKWidgetStepType,
  type SmartContract,
  TokenCurrentChain,
  isABINumber
} from "@okcontract/coredata";
import {
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type Key,
  type LabelledTypeDefinition,
  type MapTypeDefinitions,
  type TypeScheme,
  VIEW,
  mapTypeDefinitions,
  objectDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { getMergedABIX } from "./abix";
import type { OKPage } from "./instance";
import type { Step } from "./steps";
import {
  ThemePropertyDefinition,
  themeDefinition,
  themeDefinitions
} from "./theme";

const filterStepDefinition = (step: OKWidgetStep<OKWidgetStepType>) => {
  switch (step.sty) {
    case OKWidgetCallStep: {
      const { ntx, fch, sig, ...newStep } = step;
      return newStep;
    }
    case OKWidgetSigStep: {
      const { ntx, fch, m, q, ...newStep } = step;
      return newStep;
    }
    case OKWidgetNetworkTXStep: {
      const { m, q, sig, ...newStep } = step;
      return newStep;
    }
    default:
      return step;
  }
};

const uniqueMethods = (abi: Abi) => {
  if (!abi) return;
  // select non-constant methods
  const m = abi.filter(
    (abiFunc) =>
      abiFunc?.type === "function" &&
      (abiFunc.stateMutability === "payable" ||
        abiFunc.stateMutability === "nonpayable")
  ) as AbiFunction[];
  // count overloaded methods
  const count = m.reduce(
    (acc, mi) => {
      const name = mi.name; // short name
      return acc.set(name, (acc.get(name) || 0) + 1);
    },
    new Map() as Map<string, number>
  );

  // return short name if not ambiguous
  return m.map((abiFunc) =>
    count.get(abiFunc.name) === 1 ? abiFunc.name : toFunctionSignature(abiFunc)
  );
};

/**
 * okayedMethod checks if method m is okayed in ABIExtra.
 * @param x
 * @returns
 */
export const okayedMethod = (x: ABIExtra) => (m: string) =>
  x?.methods ? Object.keys(x.methods).includes(m) : false;

/**
 * methods_ok_for_widget computes the list of okayed methods for a widget (possibly none).
 * @param methods
 * @param x
 */
export const okayedMethods = (methods: string[], x: ABIExtra) =>
  x ? methods.filter(okayedMethod(x)) : methods;

/**
 * getMethods returns an array of allowed methods
 * @param abi
 * @param abix
 * @param m
 * @returns
 */
const getMethods = (
  abi: Abi,
  is_admin = false,
  abix?: ABIExtra,
  m?: string
) => {
  const methods = (abi && uniqueMethods(abi)) || [];
  return [
    ...((is_admin ? methods : okayedMethods(methods, abix)) || []),
    ...((m && [m]) || []) // cloned widget method if not already included
  ];
};

const stepAnalysis = (instance: OKPage, id: number) => {
  // console.log("stepAnalysis", { id });
  if (!id) return null;
  const proxy = instance.proxy;

  const stepCell = proxy.get(id) as Cellified<Step<OKWidgetStepType>>;
  const sty = proxy.map([stepCell], (_s) => _s?.sty || null);
  const q = proxy.map([stepCell], (_s) => _s?.q || null);

  const contract = instance.local.unwrappedCell(q) as AnyCell<SmartContract>;
  const addr = proxy.map(
    [contract, instance.wantedChain],
    (_c, _ch) =>
      _c?.addr?.find((_addr) => _addr.chain === _ch) || _c?.addr?.[0] || null,
    "an:addr"
  );

  const abi = instance._getABI(addr);
  const abix = getMergedABIX(instance.core, proxy, abi, contract);

  const methods = proxy.map(
    [abi, instance.core.IsAdmin, abix],
    (abi, adm, abix) => getMethods(abi.parsed, adm, abix),
    "an:methods"
  );
  return { sty, q, contract, abix, addr, abi, methods };
};

/**
 * getMethodDefinition returns the definition for contract call method.
 * @param core
 * @param step
 * @returns
 */
const getMethodDefinition = (instance: OKPage) => (node: EditorNode) => {
  // console.log("getMethodDefinition", { node, id: node.id });
  const proxy = instance.proxy;
  if (!node?.parent)
    return proxy.new({ base: "string", label: "Method", optional: true });
  const { sty, q, methods } = stepAnalysis(instance, node.parent);

  const defaultLTD = proxy.map([sty, q], (_sty, _q) => ({
    hidden: _sty !== OKWidgetCallStep || !_q,
    label: "Method",
    enum: [],
    optional: _sty !== OKWidgetCallStep
  }));
  const output = proxy.map([defaultLTD, methods], (_ltd, _methods) => ({
    ..._ltd,
    enum: _methods,
    default: _methods?.[0]
  }));
  return initialValue(proxy, defaultLTD, output);
};

export const widgetGroups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "main", l: "Interaction Information" }),
  proxy.new({ id: "int", l: "Intent" }),
  proxy.new({ id: "steps", l: "Interaction Specification" }),
  proxy.new({ id: "par", l: "Parameters" }),
  proxy.new({ id: "custom", l: "Customization" }),
  proxy.new({ id: "image", l: "Theme & Connection Screen" })
];

export const key_group_collapsed = (k) => `gr.${k}.clp`;

export const WidgetID = (instance: OKPage) => (node, env) =>
  instance.proxy.new({
    label: "Interaction",
    base: "string",
    search: "widget"
  } as LabelledTypeDefinition);

const OKWidgetStepDefinition =
  (instance: OKPage, mode: EditorMode) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> => {
    // const analysis = stepAnalysis(instance, node.value);
    return objectDefinition(
      instance.proxy,
      {
        sty: {
          label: "Step Type",
          hint: "Type of action performed by this widget step",
          enum: {
            [OKWidgetCallStep]: "Call a contract",
            [OKWidgetSigStep]: "Sign a message",
            [OKWidgetNetworkTXStep]:
              "Generate a transaction from OKcontract nodes"
          },
          def: OKWidgetCallStep,
          recomp: true
        },
        org: {
          label: "Organization for this step",
          hint: "This will replace contract logo in the header",
          base: "string",
          search: "org",
          optional: true,
          icon: "office-o"
        },
        q: (node: EditorNode) => {
          if (!node?.parent)
            return instance.proxy.new({ label: "Contract", base: "string" });
          const stepCell = instance.proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance.proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetCallStep,
            label: "Contract",
            base: "string",
            search: "contract",
            locked: mode === VIEW
          }));
        },
        m: getMethodDefinition(instance),
        sig: (node: EditorNode) => {
          if (!node?.parent)
            return instance.proxy.new({
              label: "Signature expression",
              base: "string",
              optional: true,
              isExpr: true
            });
          const stepCell = instance.proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance.proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetSigStep,
            label: "Signature",
            hint: "Expression that must be signed by the user",
            base: "string",
            isExpr: true,
            icon: "signature",
            optional: _sty !== OKWidgetSigStep
          }));
        },
        ntx: (node: EditorNode) => {
          if (!node?.parent)
            // @todo should not happen?
            return instance.proxy.new({
              label: "Network TX",
              base: "string",
              isExpr: true,
              optional: true
            });
          const stepCell = instance.proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance.proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Network TX",
            base: "string",
            isExpr: true,
            optional: _sty !== OKWidgetNetworkTXStep // @todo hidden should include optional?
          }));
        },
        fch: (node: EditorNode) => {
          if (!node?.parent)
            return instance.proxy.new({
              label: "Available on the following chains",
              array: () =>
                instance.proxy.new({
                  base: "string",
                  label: "Chain",
                  enum: []
                }),
              unique: true,
              optional: true
            });
          const stepCell = instance.proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance.proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Available on the following chains",
            // @todo chain_names enum
            array: () =>
              instance.proxy.new({ base: "string", label: "Chain", enum: [] }),
            // @todo unique for enum should remove existing entries from enum options
            unique: true
          }));
        },
        xm: {
          label: "",
          name: "ABIMethod"
        },
        skip: (_node: EditorNode) =>
          instance.proxy.new({
            label: "Skip the step if...",
            help: "Expression that defines whether the step should be skipped",
            base: "string",
            isExpr: true,
            optional: true,
            icon: "hand-o"
          })
      },
      "Interaction step: Transaction or Signature",
      {
        // @todo check always ValueCell
        lens: (v: ValueCell<unknown>) => {
          const conv = instance.proxy.new(undefined);
          v.subscribe((_v: OKWidgetStep<OKWidgetStepType>) => {
            conv.set(filterStepDefinition(_v));
          });
          conv.subscribe((_v) => {
            v.set(_v);
          });
          return conv;
        }
      }
    );
  };

const onlyForTypes = (
  proxy: SheetProxy,
  node: EditorNode,
  expectedTypes: string[],
  td: LabelledTypeDefinition
) => {
  // console.log("onlyForType", { node });
  // @todo should be empty and remove data if present #653
  if (!node || node.parent === null) {
    // console.log("onlyForType", { null: true });
    return proxy.new(td);
  }
  // console.log("onlyForType", { cell: proxy.get(node.parent) });
  const ty = proxy.map(
    [proxy.get(node.parent)],
    (par: {
      ty: AnyCell<string>;
    }) => par?.ty || null
  );
  return proxy.map([ty], (ty) =>
    expectedTypes.includes(ty) ? { ...td, hidden: false, isExpr: true } : td
  );
};

/**
 * TypeScheme definitions for Widget.
 * @param cons
 * @returns
 * @todo flatten without OKWidget
 */
export const widgetTypeScheme = (
  instance: OKPage,
  mode: EditorMode
): MapTypeDefinitions => ({
  OKWidget: () =>
    objectDefinition(
      instance.proxy,
      {
        id: {
          label: "Unique ID",
          base: "string",
          min: 3,
          hidden: true,
          gr: "main"
        },
        ver: {
          label: "Version",
          base: "number",
          def: 1,
          hidden: true,
          gr: "main"
        },
        name: {
          label: "Interaction name",
          pl: "ex. Swap WETH to USDC on Uniswap",
          base: "string",
          min: 5,
          gr: "main"
        },
        act: {
          label: "Active",
          base: "boolean",
          locked: true,
          gr: "main",
          hidden: true
        },
        own: {
          label: "Owner",
          base: "string",
          // isAddress: true,
          locked: true,
          gr: "main"
        },
        qri: {
          label: "Attestation Reference ID",
          base: "string",
          gr: "main",
          hidden: true
        },
        org: {
          // FIXME: choose verified org
          label: "Organization",
          base: "string",
          hidden: true,
          optional: true,
          gr: "main",
          icon: "office-o"
        },
        st: {
          label: "Steps", // "List of transactions"
          array: () => instance.proxy.new({ name: "OKWidgetStep" }),
          showAsTabs: (i) => `Step ${i + 1}`,
          min: 1,
          gr: "steps",
          hideLabel: true
        },
        [ABIMethodIntent]: {
          label: "Intent",
          icon: "edit-alt-o",
          optional: true,
          gr: "int",
          // dict: (k) =>
          //   proxy.new({
          //     label: "",
          //     base: "string"
          //     // @todo enum constraint for IntentType?
          //   }),
          // keys: new Set([IntentTypeKey])
          object: mapTypeDefinitions(
            instance.proxy,
            {
              [IntentTypeKey]: {
                label: "Type",
                enum: IntentTypes
              },
              [IntentChainKey]: {
                label: "Chain",
                base: "string",
                search: "chain",
                optional: true
              },
              from: (node) =>
                onlyForTypes(instance.proxy, node, [IntentSwap], {
                  label: "From",
                  base: "string",
                  hidden: true
                }),
              to: (node) =>
                onlyForTypes(instance.proxy, node, [IntentSwap], {
                  label: "To",
                  base: "string",
                  hidden: true
                }),
              assets: (node) =>
                onlyForTypes(
                  instance.proxy,
                  node,
                  [IntentDeposit, IntentWithdraw],
                  {
                    label: "Assets",
                    base: "string",
                    hidden: true
                  }
                )
            },
            "intent"
          )
        },
        ...themeDefinitions(instance.proxy)
      },
      "Onchain Interaction"
    ),
  OKWidgetStep: OKWidgetStepDefinition(instance, mode),
  ABIMethod: ABIMethodRef(instance.proxy),
  ABIMethodFields: ABIMethodFieldsRef(instance),
  ABIValues: ABIValuesRef(instance),
  // @todo do we need both?
  ...themeDefinition(instance.proxy),
  ThemeProperty: ThemePropertyDefinition(instance)
});

export const WidgetTypeDefinition = (): LabelledTypeDefinition => ({
  label: "OKWidget",
  name: "OKWidget"
});

export const widgetSchema = (
  instance: OKPage,
  mode: EditorMode
): TypeScheme => ({
  types: instance.proxy.new(widgetTypeScheme(instance, mode)),
  values: instance.proxy.new(WidgetTypeDefinition()),
  gs: instance.proxy.new(widgetGroups(instance.proxy))
});

export const LoadingDefinition: LabelledTypeDefinition = {
  label: "Loading",
  pl: "Loading...",
  base: "string",
  isLoader: true
};

export const ABIMethodRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      proxy,
      {
        fields: { label: "", name: "ABIMethodFields" },
        values: (node: EditorNode) => {
          // @todo required?
          // if (!node) return proxy.new({ label: "Values" });
          const valuesCell = proxy.get(node.value) as Cellified<ABIMethod>;
          return valuesCell.map(
            (_v) => ({
              label: "AR Values",
              name: "ABIValues",
              hidden: !Object.keys(_v)?.length
              // def: {}
            }),
            "ABIMethodRef.values"
          );
        }
      },
      "ABIMethod",
      {
        def: {},
        hideLabel: true,
        lens: (v: ValueCell<unknown>) => {
          const fields: Cellified<ABIMethodFields> = proxy.new(
            {},
            "lens:fields"
          );
          const values: Cellified<ABIValues> = proxy.new(
            {},
            `lens:values:${v.id}`
          );

          v.subscribe((_v: Cellified<ABIValues>["value"]) => {
            const newFields = {};
            const newValues = {
              // test: proxy.new({
              //   v: proxy.new("1"),
              //   ty: proxy.new("number")
              // }) as Cellified<ABIValue>
            } as Cellified<ABIValues>["value"];
            for (const [key, value] of Object.entries(_v)) {
              if (key.startsWith("@")) {
                newFields[key] = value;
              } else {
                newValues[key] = value;
              }
            }
            fields.update((current) => ({ ...current, ...newFields }));
            values.update((current) => ({ ...current, ...newValues }));
          });
          const obj = proxy.map(
            [fields, values],
            () => ({ fields, values }),
            "lens:obj"
          );

          obj.subscribe(async (_obj) => {
            if (_obj instanceof Error) throw new Error(`lens failed: ${_obj}`);
            const result = {};
            const fields = await _obj.fields.consolidatedValue;
            const values = await _obj.values.consolidatedValue;
            Object.assign(result, fields ?? {});
            Object.assign(result, values ?? {});
            v.set(result);
          });
          // console.log("ABIMethod lens", { obj });
          return obj;
        }
      }
    );

export const ABIMethodFieldsRef =
  (instance: OKPage) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> => {
    const proxy = instance.proxy;
    const element = proxy.new({
      label: "Text or expression",
      base: "string",
      isExpr: true,
      compact: true,
      def: '""'
    } as LabelledTypeDefinition);
    return objectDefinition(
      proxy,
      {
        [ABIMethodAllowances]: {
          label: "Allowances",
          icon: "dollar-o",
          optional: true,
          dict: (k) =>
            proxy.new({
              label: "",
              base: "string",
              isExpr: true,
              compact: true
            }),
          gr: "par"
        },
        [ABIMethodPre]: {
          label: "Pre-Conditions",
          icon: "code-bracket-o",
          array: () =>
            proxy.new({
              label: "Pre-condition",
              base: "string",
              isExpr: true,
              add: true,
              compact: true
            }),
          optional: true,
          gr: "par"
        },
        [ABIMethodTitle]: {
          label: "Title elements",
          icon: "tag-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodInfo]: {
          label: "Information text",
          icon: "bars-left-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodButton]: {
          label: "Button name",
          array: () => element,
          min: 1,
          max: 1,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodImage]: {
          label: "Image",
          hint: "url, IPFS url or expression",
          icon: "image",
          base: "string",
          isExpr: true,
          isImg: true,
          optional: true,
          compact: true,
          gr: "custom"
        },
        [ABIMethodRcpt]: {
          label: "Recipients",
          icon: "users-o",
          array: () => element,
          optional: true,
          hidden: true,
          gr: "custom"
        },
        [ABIMethodAdm]: {
          label: "Admin reserved",
          icon: "user-c-o",
          base: "boolean",
          optional: true,
          gr: "custom"
        },
        [ABIMethodAfter]: {
          label: "After completion definitions",
          icon: "edit-alt-o",
          // optional: true,
          gr: "par",
          dict: (k) =>
            proxy.new({
              label: "",
              base: "string",
              isExpr: true
            })
        }
      },
      "ABIMethodFields"
    );
  };

const SEARCH_LIST = [
  TokenCurrentChain,
  "token",
  "nft",
  "contract",
  "swap",
  "stake"
];

const ABIValueTypeGen = (proxy: SheetProxy, key: Key) => {
  const def =
    key && typeof key === "string" && key.toLowerCase().includes("token")
      ? TokenCurrentChain
      : undefined;
  return {
    label: "Type",
    icon: "variable-o",
    enum: ABIValueTypes,
    // @todo do we need this?
    // lens: (v: ValueCell<ABIValueType>) => {
    //   const type: ValueCell<ABIValueType> = proxy.new(undefined, "lens:ty");
    //   v.subscribe((_v) => {
    //     type.set(isABINumber(_v) ? "number" : _v);
    //   });
    //   type.subscribe((_type) => {
    //     v.set(_type);
    //   });
    //   return type;
    // },
    optional: !def,
    def,
    cssView: "badge badge-secondary badge-outline"
  };
};

export const ABIValuesRef =
  (instance: OKPage) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> => {
    const proxy = instance.proxy;
    return proxy.new({
      label: "ABIValues",
      compact: true,
      dict: (node: EditorNode) => {
        const key = node?.key;
        return objectDefinition(
          proxy,
          {
            v: (node: EditorNode, env: Environment) => {
              const parent = node?.parent
                ? proxy.get(node.parent)
                : instance.null;
              const ty = proxy.map(
                [parent],
                (par: {
                  ty: AnyCell<string>;
                }) => par?.ty || null
              );
              const search = ty.map((_ty) =>
                _ty && SEARCH_LIST.includes(_ty) ? _ty : null
              );
              return search.map(
                (_search) => ({
                  label: "Expression",
                  icon: "formula",
                  base: "string",
                  isExpr: true,
                  def: "",
                  optional: true,
                  search: _search || undefined,
                  compact: true
                }),
                `av:v.${key}`
              );
            },
            ty: ABIValueTypeGen(proxy, key),
            l: {
              label: "Label",
              icon: "tag-o",
              base: "string",
              optional: true,
              compact: true
            },
            em: {
              label: "Enum",
              icon: "hashtag-o",
              base: "string",
              isExpr: true,
              optional: true,
              compact: true
            },
            desc: {
              label: "Description",
              icon: "bars-left-o",
              base: "string",
              optional: true,
              compact: true
            },
            pl: {
              label: "Placeholder",
              icon: "text",
              base: "string",
              optional: true
            },
            a: {
              label: "Amount definition",
              icon: "number",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            def: {
              label: "Default",
              icon: "code-bracket-o",
              optional: true,
              base: "string",
              isExpr: true
            },
            min: {
              label: "Minimum value",
              icon: "minus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            max: {
              label: "Maximum value",
              icon: "plus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            x: {
              label: "Allow external definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            opt: {
              label: "Optional definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            dec: {
              label: "Optional decimals",
              icon: "number",
              optional: true,
              base: "number",
              compact: true
            }
          },
          "ABIValue",
          { cssView: "flex" }
        );
      }
    });
  };
