import type { AnyCell, SheetProxy } from "@okcontract/cells";
import {
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  objectDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { submitter } from "./data-user";
import type { OKPage } from "./instance";

export const hostGroups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "main", l: "Host" })
];

/**
 * schema for coredata.Host
 */
export const hostSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance.proxy, {
      h: {
        label: "Host Hash",
        base: "string",
        gr: "main"
      },
      from: submitter("main")
    });
