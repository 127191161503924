<script lang="ts">
  import { ValueCell, type AnyCell, type Key } from "@okcontract/cells";
  import { DataEditor, type EditorNode } from "@okcontract/fred";
  import { reservedKeywords } from "@okcontract/lambdascript";
  import { EnvKeyMissingKeys, type OKPage } from "@okcontract/sdk";
  import { Button, Hoverable, MasterDetail } from "@okcontract/uic";
  import { SearchSelector, SearchSelectorClass } from "@scv/selector";

  import type { EditorOptions } from "../types";
  import EditorNodeCell from "./EditorNodeCell.svelte";

  export let instance: OKPage;
  const proxy = instance.proxy;

  export let editor: DataEditor;
  export let node: EditorNode;
  const value = proxy.get(node.value) as ValueCell<
    Record<Key, AnyCell<unknown>>
  >;
  const definition = node.definition;

  export let options: EditorOptions = {};
  const env = editor.env;

  const selected = proxy.new("", "ODE:selectedInput");
  const searchSelected = proxy.new("", "ODE:searchSelected");

  const dict = "dict" in node && node.dict;
  const isEditable = !options?.view && value instanceof ValueCell;

  const dictValue = value.map(
    (_value) => Object.entries(_value).sort(),
    "ODE:dictValue"
  );
  dictValue.subscribe(
    (_value) => _value?.[0]?.[0] && selected.set(_value[0][0])
  );

  const dictKeys = definition.map(
    (def) => ("keys" in def ? def.keys : new Set<string>()),
    "ODE:dictKeys"
  );

  const existingKeys = value.map((obj) => Object.keys(obj), "ODE:existingKeys");

  // @todo move to schema definition
  const EKM = (env?.values?.[EnvKeyMissingKeys] ||
    proxy.new([], "EKM")) as AnyCell<string[]>;

  /**
   * searchFn computes the list of potential completions from the search query.
   * @param q
   */
  const searchFn = proxy.map(
    [dictKeys, existingKeys, EKM],
    (dKeys, existing, envKeys) =>
      // @todo remove async, update type in selector package
      async (q: string) => {
        const ql = q.trim().toLowerCase();
        const keys = (Array.from(dKeys) || []).filter((s) =>
          s.toLowerCase().includes(ql)
        );
        const comp = (envKeys || []).filter(
          (v) =>
            // key defined (not case sensitive)
            v?.toLowerCase()?.includes(ql) &&
            // not already in Dict
            !existing.includes(v)
        );
        return Array.from(new Set([...keys, ...comp]));
      }
  );

  const canCreateFn = proxy.map([dictKeys], (keys) =>
    // @todo remove async, update type in selector package
    async (key: string, l: string[]) => {
      const ll = (l || []).map((v) => v.toLowerCase());
      const kl = key.toLowerCase();
      return (
        // not reserved
        !reservedKeywords.includes(kl) &&
        // already present
        !keys.has(kl) &&
        // in completions
        !ll.includes(kl)
      );
    }
  );

  const onCreate = async (ev: CustomEvent) => {
    // console.log("CREATE");
    const { input: key, component } = ev.detail as {
      input: string;
      component: SearchSelectorClass<string>;
    };
    component.close();
    component.input.set("");
    await editor.addNewProperty(node, key);
    await instance.proxy.working.wait();
    selected.set(key);
  };

  const onEmpty = (ev: CustomEvent) => {
    // console.log("EMPTY");
    const searchSelector = ev.detail as SearchSelectorClass<string>;
    searchSelector.close();
  };

  const onSelect = (ev: CustomEvent) => {
    // console.log("SELECT");
    const key = ev.detail.selected;
    const searchSelector = ev.detail.component as SearchSelectorClass<string>;
    searchSelector.close();
    searchSelector.input.set("");
    selected.set(key);
  };
</script>

{#if $definition instanceof Error}
  Error $definition: {$definition}
{:else if $dictValue instanceof Error}
  Error $dictValue: {$dictValue}
{:else if $dict instanceof Error}
  Error $dict: {$dict}
{:else if $dict}
  {#if isEditable}
    <div class="item-center flex space-x-3">
      <SearchSelector
        {instance}
        selected={searchSelected}
        {searchFn}
        placeholder="ex. Type $ or a letter to autocomplete"
        createLabel="Create value"
        {canCreateFn}
        on:create={onCreate}
        on:select={onSelect}
        on:empty={onEmpty}
        let:value
      >
        {value}
      </SearchSelector>
    </div>
  {/if}

  {#if options?.view && "compact" in $definition && $definition.compact}
    {#each Object.entries($dict).sort() as [k, child]}
      <dl class="flex gap-2 py-2 px-1 border-t border-base-300">
        <dt class="flex-none w-40 break-all font-medium">{k}</dt>
        <dd>
          <EditorNodeCell
            {instance}
            {editor}
            node={child}
            options={{ ...options, hideLabels: true }}
          />
        </dd>
      </dl>
    {/each}
  {:else}
    <div class="mt-1 border border-base-300 bg-base-100 rounded-btn">
      <div class="md:grid md:grid-cols-3">
        <MasterDetail
          data={Object.entries($dict).sort()}
          title={$definition.label}
          selectedKey={selected}
          size="sm"
        >
          <span slot="master" let:key class="">
            <Hoverable let:hovering={hover}>
              <div class="flex flex-row items-center justify-between">
                <span class="py-2">{key}</span>
                {#if hover && isEditable}
                  <Button
                    style="ghost"
                    size="xs"
                    square={true}
                    iconAppend={true}
                    icon="close"
                    action={() => editor.removeProperty(node, key)}
                  />
                {/if}
              </div>
            </Hoverable>
          </span>
          <span
            slot="detail"
            let:value={child}
            class="bg-base-200 p-2 md:col-span-2"
          >
            {#key child}
              <EditorNodeCell {instance} {editor} node={child} {options} />
            {/key}
          </span>
        </MasterDetail>
      </div>
    </div>
  {/if}
{/if}
