import { getContext, onDestroy } from "svelte";

import {
  type OKCore,
  OKCoreKey,
  OKPage,
  type OKPageOptions
} from "@okcontract/sdk";

export const useCore = (): OKCore => getContext(OKCoreKey);

/**
 * instance creates a local instance for a Svelte component of the OKcontract SDK.
 * @returns instance
 * @description instance is automatically destroyed on component shutdown.
 */
export const newOKPage = (name?: string, options: OKPageOptions = {}): OKPage =>
  new OKPage(useCore(), {
    local: true,
    rpc: true,
    name,
    ...options,
    onDestroy
  });
