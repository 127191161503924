<script lang="ts">
  import {
    contract_org_query,
    is_anon_contract,
    type ContractQueryType
  } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Icon, getTheme } from "@okcontract/uic";
  import { Logo, Upvote } from "@scv/dcuic";

  export let instance = newOKPage("SmartContractSnippet");
  export let q: ContractQueryType = null;
  export let small: boolean = false;
  export let upvote: boolean = false;

  const core = instance.core;
  const local = instance.local;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const qCell = proxy.new(q, `SmartContractSnippet.qCell:${q}`);
  $: qCell.set(q);

  const con = local.unwrappedCell(qCell, "con");
  const anon = con.map((_con) => is_anon_contract(_con), "anon");

  const orgQuery = qCell.map(
    (_q) => contract_org_query(_q) || null,
    "SmartContractSnippet.orgQuery"
  );
  const org = local.unwrappedCell(orgQuery, "org");

  const logo = proxy.map(
    [anon, con, org],
    (_anon, _con, _org) =>
      (_anon ? _con?.addr?.[0]?.chain : _org?.logo || _org?.id) || null,
    "SmartContractSnippet.logo"
  );
</script>

<div class="flex grow items-center justify-between">
  {#if !($con instanceof Error)}
    <div class="flex items-center">
      {#if !($logo instanceof Error)}
        <div class="shrink-0">
          <Logo
            logo={$logo}
            size={small ? "sm" : "md"}
            remote={!core.options?.lic}
          />
        </div>
      {/if}
      <div
        class="mx-1 flex-1 {theme.dark(
          $compiledTheme,
          'text-white',
          'text-gray-900',
          'text-base-content'
        )} {small ? 'text-sm' : 'ml-2 text-base font-semibold'}"
      >
        {($con &&
          ($con.id || `${$con.addr[0].addr} on ${$con.addr[0].chain}`)) ||
          q?.replace("con:", "")}
      </div>
      <slot name="tag" />
      {#if !$anon && $con && !$con?.act}
        <div class="tooltip text-warning" data-tip="Inactive smart contract">
          <!-- <ActiveStatus act={false} />  -->
          <Icon name="error" size="sm" />
        </div>
      {/if}
    </div>
  {/if}

  {#if upvote}
    <Upvote {q} style="sm" />
  {/if}
</div>
<!--
    <div
    class="shrink-0 flex rounded-full bg-purple-100 dark:bg-transparent  items-center justify-center {small
      ? 'h-4 w-4'
      : 'h-6 w-6'}"
  >
  </div>
-->
