<script lang="ts">
  import type { HostQueryType } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";

  export let instance = newOKPage("HostSnippet");
  export let q: HostQueryType;
  export let small: boolean = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const local = instance.local;

  const host = local.staticQuery(q, "host");
</script>

<div class="flex flex-row items-center justify-between">
  <div class="flex items-center">
    <div
      class="{small ? 'w-4' : 'w-6'} {theme.dark(
        $compiledTheme,
        'text-white',
        'text-black',
        'text-base-content'
      )}"
    >
      {#if $host && !($host instanceof Error)}
        {$host.h}
      {/if}
    </div>
  </div>
</div>
