<script lang="ts">
  import { cellify, type AnyCell } from "@okcontract/cells";
  import { ipfs_rewrite, type OKPage } from "@okcontract/sdk";
  import { Icon } from "@okcontract/uic";
  import { Image } from "@scv/dcuic";

  import ObjectView from "./ObjectView.svelte";

  export let instance: OKPage;
  export let url: AnyCell<string>;
  export let type: AnyCell<"url" | "ipfs" | "embedded" | null>;

  // export let size: "sm" | "smb" | "md" = "sm";

  // rewrite url if necessary
  const rewriteURL = ipfs_rewrite(url);
  const response = instance.proxy.map([rewriteURL, type], (_url, _type) => {
    if (_type === "url") {
      return instance.proxyHead(url);
    }
    return fetch(_url);
  });
  const responseType = response.map((_res) => {
    const contentType = _res?.headers.get("content-type");
    return contentType === "application/json"
      ? "json"
      : contentType.startsWith("image/")
        ? "image"
        : contentType.startsWith("text/html")
          ? "html"
          : "image";
  }) as AnyCell<"json" | "image" | "html">;

  const value = instance.proxy.map(
    [responseType, response],
    async (_type, _res) => {
      if (_type === "json") {
        const json = await instance
          ._proxyFetch(rewriteURL)
          .map((_fetched) => _fetched.json())
          ?.get();
        if (json instanceof Error) throw json;
        return cellify(instance.proxy, json);
      }
      return null;
    }
  );
</script>

{#if $responseType instanceof Error}
  Error responseType
{:else if $value instanceof Error}
  Error value
{:else if $url instanceof Error}
  Error url
{:else if $responseType === "json"}
  <ObjectView {instance} {value} />
{:else if $responseType === "image"}
  <Image src={$url} />
{:else if $responseType === "html"}
  <a href={$url} target="_blank" rel="noreferrer" class="link link-primary">
    <span class="mr-1"><Icon name="external" size="sm" /></span>
    Open in new tab
  </a>
{/if}
