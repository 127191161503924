<script lang="ts">
  import type { AbiFunction } from "abitype";

  import type { AnyCell } from "@okcontract/cells";
  import { DataEditor, newSchema, objectDefinition } from "@okcontract/fred";
  import {
    Environment,
    newTypeScheme,
    typeString
  } from "@okcontract/lambdascript";
  import type { ChainType } from "@okcontract/multichain";
  import {
    EnvKeyChain,
    abiArgDefinition,
    formatError,
    type OKPage
  } from "@okcontract/sdk";
  import { DataEditorLight } from "@scv/dataeditor";

  export let instance: OKPage;
  const proxy = instance.proxy;

  export let fn: AbiFunction;
  export let values: AnyCell<{ [key: number]: AnyCell<unknown> }>;
  export let chain: AnyCell<ChainType>;

  const env = new Environment(instance.proxy);
  env.addValueType(EnvKeyChain, chain, newTypeScheme(typeString));

  const envCell = proxy.new(env);
  const object = Object.fromEntries(
    fn.outputs.map((param, i) => {
      return [
        i,
        abiArgDefinition(
          instance,
          envCell,
          instance.null,
          instance.null,
          proxy.new(param)
        )
      ];
    })
  );

  // @todo should be in core?
  const types = proxy.new(instance.dataTypeScheme());
  const schema = newSchema(
    instance.proxy,
    objectDefinition(proxy, object),
    types
  );
  const editor = new DataEditor(instance.proxy, values, schema, env);
</script>

<DataEditorLight
  {instance}
  {editor}
  options={{ noCollapsible: true, view: true, formatError }}
></DataEditorLight>
