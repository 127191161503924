const mock: Record<string, string> = {};

/**
 * set a cookie .
 */
const set = (key: string, value: string, days = 3) => {
  if (typeof document === "undefined") {
    mock[key] = value;
    return;
  }
  const expires =
    days !== undefined
      ? `; expires=${new Date(
          Date.now() + days * 24 * 60 * 60 * 1000
        ).toUTCString()}`
      : "";
  const data = `${key}=${value}${expires}; path=/; SameSite=None; Secure`; // Strict
  document.cookie = data;
};

/**
 * get a cookie.
 */
const get = (key: string) => {
  if (typeof document === "undefined") return mock[key] || null;
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(`${key}=`);
    if (c_start !== -1) {
      c_start = c_start + key.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return null;
};

/**
 * init a cookie with a new value if not found.
 */
const init = (key: string, fn: () => string, days = 3) => {
  const prev = get(key);
  if (prev !== null) return prev;
  const value = fn();
  set(key, value, days);
  return value;
};

/**
 * delete a cookie.
 */
const del = (key: string) => {
  if (typeof document === "undefined") {
    mock[key] = undefined;
    return;
  }
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=None; Secure`;
};

/**
 * manages cookies.
 */
export const cookie = { set, get, init, delete: del };
