import { type CacheQuery, type DataCacheType, newCachedData } from "./coredata";
import { emptyPartitionFromQuery } from "./partition";
import { TokenCurrentChain } from "./types";
import type { VirtualQueryType } from "./virtual";

/**
 * data_prefixes for each DataType.
 * @todo should be retrieved from server as static JSON
 */
const dataPrefixes = {
  contract: "con:",
  org: "org:",
  token: "tok:",
  [TokenCurrentChain]: "tok:",
  nft: "nft:",
  stake: "stake:",
  swap: "swap:",
  lend: "lend:",
  upvote: "upvote:",
  person: "pp:",
  widget: "wid:",
  abix: "abix:",
  part: "part:", // @todo unused
  address: "addr:",
  method: "met:",
  abi: "abi:",
  chain: "§",
  price: "$",
  user: "user:",
  uf: "->",
  page: "pg:",
  lst: "lst:",
  lq: "lq:",
  χuf: "χuf:",
  χauf: "χauf:",
  role: "role:",
  // gas: "!",
  balance: "bal:",
  host: "host:"
  // tokens: "*"
} as const;

// @todo comment, where is it used?
const invertedPrefixes = Object.fromEntries(
  Object.entries(dataPrefixes).map(([k, v]) => [v, k])
) as {
  [k: string]: DataCacheType;
};

export const removeAllPrefixes = new RegExp(
  Object.keys(invertedPrefixes).join("|"),
  "g"
);

/**
 * client-side computed default values when server-side data is missing.
 */
export const prefixDefaultValue = {
  part: emptyPartitionFromQuery
} as const;

const prefixDefaultEntries = Object.entries(prefixDefaultValue);

const queryFromPrefix = (q: CacheQuery) => {
  for (const [k, v] of prefixDefaultEntries) {
    if (q?.startsWith(`${k}:`)) {
      return newCachedData(
        invertedPrefixes[`${k}:`],
        q,
        v(q),
        Date.now() / 1000 + 60 // @todo defined for each type
      );
    }
  }
};

export const defaultCachedData = (ql: CacheQuery[]) =>
  ql.map(queryFromPrefix).filter((v) => v !== undefined);

// We retrieve the keys (not exported).
export type DataCacheKey = keyof typeof dataPrefixes;
// And export the type of values for each key.
export type DataCachePrefix = (typeof dataPrefixes)[DataCacheKey];

/**
 * datatypes from each prefix
 * @todo refactor?
 */
export const prefixToType = (pfx: string) =>
  pfx === "tok" ? "token" : (invertedPrefixes[`${pfx}:`] as DataCacheKey);

export const prefixFromType = (ty: DataCacheKey) =>
  dataPrefixes[ty] as DataCachePrefix;

/**
 * strip_datacache_prefix removes any datacache prefixes
 * of a string if found
 * @param q
 * @returns
 * @todo different implementation?
 */
export const strip_datacache_prefix = (str: string) =>
  str && typeof str === "string"
    ? // @todo only one substitution?
      str.replace(removeAllPrefixes, "")
    : "";

export const IndexPrefixQuery = <T extends DataCacheKey>(
  ty: T,
  prefix: string
): IndexPrefixQueryType<T> => `χ${ty}.prefix:${dataPrefixes[ty]}${prefix}`;

export type IndexPrefixQueryType<T extends DataCacheKey> =
  | VirtualQueryType<`${T}.prefix`, string>
  | VirtualQueryType<`${T}.prefix`, `${string}:${string}`>;
