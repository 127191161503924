const DEV = false;

import { jsonStringify } from "@okcontract/cells";
import { type EditorMode, VIEW } from "@okcontract/fred";
import {
  type Address,
  type Chain,
  type ChainAddress,
  type ChainType,
  type Network,
  getStringAddress,
  isStringAddress
} from "@okcontract/multichain";
import {
  ADMIN,
  type ForeignAccount,
  MANAGER,
  type Role,
  USER,
  type User,
  type UserRole
} from "@scv/auth";

import { myWallet } from "./address";
import type {
  AllAnonContractQueryType,
  AnonContractQueryType,
  ContractQueryType
} from "./contract";
import type {
  EASAttestation,
  EASQueryQueryType,
  EASRecipientsQueryType
} from "./eas";
import type { DataError } from "./error";
import type { Host, HostQueryType } from "./host";
import type { IntentMatchQueryType } from "./intent";
import type { LooksRareOrderQueryType, LooksRareResponse } from "./looksrare";
import type { OrgQueryType, Organization } from "./org";
import type { OKWidgetPage, OKWidgetPageQueryType } from "./page";
import type { Partition, PartitionQueryType } from "./partition";
import {
  type DataCacheKey,
  type IndexPrefixQueryType,
  prefixToType
} from "./prefix";
import type {
  LendQueryType,
  NFTQueryType,
  OKToken,
  StakeQueryType,
  SwapQueryType,
  TokenItem,
  TokenQueryType
} from "./token";
import {
  type ABI,
  type ABIExtra,
  type BalanceData,
  type GasPriceData,
  type IPFSUpload,
  type Person,
  type SmartContract,
  TokenCurrentChain,
  type TokenMarketData
} from "./types";
import type {
  RoleQueryType,
  UserForeignQueryType,
  UserForeignSearchType,
  UserQueryType,
  VirtualAllForeignQueryType
} from "./user";
import { VirtualQuery, type VirtualQueryType } from "./virtual";
import {
  type NetworkTX,
  type OKWidget,
  OKWidgetNetworkTXStep,
  type WidgetQueryType
} from "./widget";

/**
 * SearchPage is a page of search results for a given query.
 *
 * Search results can have different types.
 */
export interface SearchPage {
  /** query */
  q: string;
  /** number of results asked */
  count: number;
  /** results */
  res: CacheQuery[];
}

/**
 * IndexPage is a page listing the index of a given type.
 * @todo merge with SearchPage
 */
export interface IndexPage<T extends IndexableTypes> {
  /** type of data */
  ty: T;
  /** page number */
  page: number;
  /** size of the page */
  size?: number;
  /** prefix */
  pre?: string;
  /** total length*/
  len?: number;
  /** result queries */
  res: CacheQueryFromType<T>[];
}

/**
 * DataCacheType is the type of a DataCache Data.
 */
// export type DataCacheType = (typeof DataCacheTypes)[number];

// @todo extend to all base types
// cspell:disable-next-line
// 4b, 4bp, LocalFilePath, abi, abix, address, affiliate, balance, bucket, chunk, claim, contract, convals, gas, lr:ord, nftitem, ntx, org, osl, page, part, person, price, stats, string, subscription, token, tokeninfo, tokens, trust, twitto, txr, up, upvote, url, waltok, widget
export type IndexableTypes =
  | "org"
  | "token"
  | "contract"
  | "person"
  | "abi"
  | "abix"
  | "upvote"
  | "widget"
  | "chain"
  | "page"
  | "host";

/**
 * type_of_cache_query returns the type of a cache query.
 * @see related to the inverse data_prefixes
 * @param q
 * @returns
 * @todo deprecated, use dataPrefixes
 */
export const type_of_cache_query = <T extends DataCacheType>(
  q: CacheQueryFromType<T> | typeof myWallet | "none"
): DataCacheKey => {
  if (!q || typeof q !== "string") {
    return;
  }
  if (q === "none") return;
  switch (q[0]) {
    case "$":
      return "price";
    case "§":
      return "chain";
  }
  if (q === myWallet) {
    return "address";
  }
  const pfx = q.substring(0, q.indexOf(":"));
  switch (pfx) {
    case "χcon.anon":
      return "contract";
    default:
      if (prefixToType(pfx)) return prefixToType(pfx);
  }

  DEV && console.log({ err: "unknown type", q, pfx });
};

/**
 * write_type returns the write type of a DataCacheType.
 * @param ty
 * @returns
 */
export const write_type = (
  ty: DataCacheType | typeof TokenCurrentChain
): WritableDataType => {
  switch (ty) {
    case "nft":
    case "stake":
    case "swap":
    case "lend":
    case TokenCurrentChain:
      return "token";
  }
  if (is_writable_data(ty)) return ty;
};

// market, etc.
export type TokenMarketQueryType = `\$${string}`;
export type GasQueryType = `!${string}`;
export type BalanceQueryType<C extends ChainType> =
  `bal:${C}:${string}:${string}`;
export type TokensListQueryType = `*`;

// coredata

export type PersonQueryType = `pp:${string}`;
// @todo decide: the URL scheme is "/"
export type MethodQueryType =
  | `met:${string}:${string}`
  | `met:${string}/${string}`;
export type ProxyQueryType = VirtualQueryType<"proxy", string>;
export type VirtualProxyQueryType = VirtualQueryType<"proxy.head", string>;
export type URLQueryType = `url:${string}`;
export type IPFSUploadQueryType =
  | `up:${string}:${string}`
  | `up:${string}:${string}:${number}`;

export type ChainQueryType = `§${string}`;
// index and search
const TokenByChainPrefix = "tCh.pfx" as const;
const TokenByChainPage = "tCh" as const;

export type SearchQueryType<T extends DataCacheType> =
  | VirtualQueryType<"search", string>
  | VirtualQueryType<`search.${T}`, string>
  | VirtualQueryType<"user.ws", string>;

export type DataCacheQueryType<T extends DataCacheType> = `${T}:${string}`;

export type IndexPageQueryType<T extends IndexableTypes> = VirtualQueryType<
  `${T}.page`,
  `${number}|${number}`
>;

export type IndexPopularQueryType<T extends DataCacheType> = VirtualQueryType<
  `${T}.pop.page`,
  `${number}|${number}`
>;

export type TokenByChainPageType<Q extends ChainType> = VirtualQueryType<
  typeof TokenByChainPage,
  `${Q}:${number}|${number}`
>;

export type TokenByChainPrefixType<Q extends ChainType> = VirtualQueryType<
  typeof TokenByChainPrefix,
  `${Q}|${string}`
>;

export type ABIQueryType<Q extends ChainType> = `abi:${Q}:${string}`;
export type ABIExtraQueryType = `abix:${string}`;
export type UpvoteQueryType<Q extends CacheQueryUpVotable> =
  `upvote:${string}:${Q}`; // FIXME: address
export type ClaimQueryType<Q extends CacheQueryClaimable> =
  `claim:${string}:${Q}`; // FIXME: address
/** total upvotes (by all users) */
export type UpvotesQueryType<Q extends CacheQueryUpVotable> = VirtualQueryType<
  "upvotes",
  Q
>;

export type ChunkQueryType = `chunk:${string}:${string}`;

export type AddressLookupQueryType<C extends ChainType> =
  | VirtualQueryType<"address", string>
  | VirtualQueryType<"address", `${string}|${C}`>;

export type TimeLookupQueryType = VirtualQueryType<"time", "">;

export type NetworkTXQueryType = VirtualQueryType<`ntx.${string}`, string>;

/**
 * CacheQuery defines types for DataCache queries.
 *
 * CacheQuery is used to introduce dependent types for CachedData, defined below.
 *
 * CacheQueryClaimable are editable.
 */
export type CacheQueryClaimable =
  | OrgQueryType
  | ContractQueryType
  | TokenQueryType
  | NFTQueryType
  | PersonQueryType;

export type CacheQueryUpVotable =
  | CacheQueryClaimable
  | SearchQueryType<DataCacheType>
  | ABIQueryType<ChainType>
  | MethodQueryType
  | WidgetQueryType
  | AnonContractQueryType<ChainType>
  | OKWidgetPageQueryType;

export const anonContractURL = (address: ChainAddress<Network>) =>
  `/contract/@${address.chain}/${address.addr}`;

export const interactionURL = (
  wid: WidgetQueryType,
  mode: EditorMode = VIEW
) => {
  const l = wid.split(":");
  return `/interaction/${l[1]}/${mode}`;
};

/**
 * link_of_cache_query generates a static URL for the given query.
 * @param q
 * @returns
 * @todo NFT custom page
 * @todo only use this function when navigating
 * @todo implement the edit option and use it
 */
export const link_of_cache_query = (
  q: CacheQuery | Address<Network>,
  mode: "create" | "edit" | "delete" = "create"
) => {
  const l = q.toString()?.split(":") || [];
  if (l.length < 2) {
    return "/home";
  }
  if (mode === "edit") return `/edit/${q}`;
  switch (l[0]) {
    case "upvote":
      return; // no redirect
    case "org":
      if (mode === "delete") return "/new/org";
      return `/org/${l[1]}`;
    case "tok":
      if (mode === "delete") return "/new/token";
      return `/token/${l[1]}`;
    case "nft":
    case "stake":
    case "lend":
    case "swap":
      if (mode === "delete") return "/new/token";
      return `/token/${l[0]}:${l[1]}`;
    case "con":
      if (mode === "delete") return "/new/contract";
      return `/contract/${l[1]}`;
    case "pp":
      return `/person/${l[1]}`;
    case "met":
      return `/contract/${l[1]}/${l[2]}`;
    case "wid":
      if (mode === "delete") return "/interactions";
      return `/interaction/${l[1]}/view`;
    case "abix":
      return `/abix/${l[1]}`;
    case "pg":
      return `/page/${l[1]}`;
    case "user":
      return `/user/${l[1]}`;
  }
  return "/home";
};

export const index_page_of_type = (ty: DataCacheType, page: number) => {
  switch (ty) {
    case "org":
      return `/orgs/${page}`;
    // case "contract":
    //   return `/contracts/${page}`;
    // case "token":
    //   return `/tokens/${page}`;
  }
};

// FIXME: extract string from type to avoid repeating each pattern for each datatype
export type ReverseIndexType<T> = T extends "|org"
  ? "org"
  : T extends "|contract"
    ? "contract"
    : T extends "|token"
      ? "token"
      : T extends "|price"
        ? "price"
        : // : T extends "|nft"
          // ? "nft"
          T extends "|person"
          ? "person"
          : // virtual queries: list by prefix, etc.
            // `χ(ty).${(command)}:${(params)}`
            // e.g.
            // VirtualQuery("org.prefix", "aa") to retrieve the list of orgs that begin with "aa"
            T extends VirtualQueryType<`org.${string}`, string>
            ? "org"
            : T extends VirtualQueryType<`contract.${string}`, string>
              ? "contract"
              : T extends VirtualQueryType<`token.${string}`, string>
                ? "token"
                : T extends VirtualQueryType<`person.${string}`, string>
                  ? "person"
                  : T extends VirtualQueryType<`abix.${string}`, string>
                    ? "abix"
                    : // parts
                      T extends `part:${string}:${string}:${string}` // @todo unused
                      ? "part"
                      : never;

/**
 * CachedData is a cached data.
 * @summary implements dependent type for object with query key
 * @link https://www.typescriptlang.org/play?#code/C4TwDgpgBAkgdmArsA0hEBnKBeKByRDCAJzygB98AjRASwBsATWuAczwFgAob0SKAKpFi8JMABitCEzQgc+AGa1iGYADkAhgFsIZSnnobVmnXvwbWu3uGgBBRo2IQMGUcknTGs+QZYQAjGa+cBAATEEAXrRgnDxcAPTxfNBuwACixMQA9sTyAEoQAMY5jAA8qbIYADRQAN4A2gDW6ABcUKrELKwAum0dXQC+AHzc3CzAJAoahSkIyBnZxACyGnCIGvR13FA7UIQkbbXbuydKKuraEAD8fcCdbADcxyc7hsaXN+13XU9cL7sWa63e6sX4nAZVZ5QDQOJwuQ5Qk70Pz+T79R6I3bIkKhNHfDF-f7Qyx4kFg3YDbiUuLJWBzVDoFZgMBdeRHQk7fbENoAIjO7x0PIoUB5bwuguFPMBPPJOxhjmcGF52ICQsoor8oTVIqiYB5VOs-FSCxybKh9RQUBYUGaICyCjpYlkTJZbF6UAAChpiMBaBtSgVisQyhVGRpmV0Ld0aujWEMRlwBr9DdAlsh1vR6CA0gAPCZwRgYUoAFSgEDzEALWAENQEZYrVagADUak35MWhvIAG5ZWiMX60pskWgKEDFgAWGmAxZsGFsTgnEAAypd5GngBms7n84XyvSTcQasbMjkVmsNkMHlBElAso0NCBRnFinBVFBWFksowD0rHfMT7kuDsicXIIhyLz8uKEBtHgkjnFAcCrlohDAFAVDQJWExOIweCQuBEJQvKcK-rUBGJleN53g+T4vm+VAwj+bTHosZrgaBWzgUiRhQby9GMAhlw8nhRJQCq-i8p+YAYEKN4kIsACEUJkScRGKmBIlibxMKiX4QmYnKlgSVkUkyfEZYAYp+EGlwQA
 */
export type CachedData<Ty extends DataCacheType> = {
  /** ty*/
  ty: Ty;
  /** query copy */
  q: Definitions[Ty]["q"];
  /** data */
  data: Definitions[Ty]["data"];
  /** time */
  at: number;
  /** data expiry time */
  exp?: number;
  /** signature */
  sig?: `0x${string}`;
  /** optional link */
  lnk?: Definitions[Ty]["q"];
};

export const newCachedData = <Ty extends DataCacheType>(
  ty: Ty,
  q: Definitions[Ty]["q"],
  data: Definitions[Ty]["data"],
  exp?: number
): CachedData<Ty> => ({
  ty,
  q,
  data,
  at: Date.now() / 1000,
  exp
});

export type List<Ty extends DataCacheType> = {
  id: string;
  l: CachedData<Ty>[];
};
export type ListQuery<Ty extends DataCacheType> = {
  id: string;
  l: CacheQueryFromType<Ty>[];
};
export type ListQueryType = `lst:${string}`;
export type ListQueryQueryType = `lq:${string}`;

export type Definitions = {
  lst: { q: ListQueryType; data: List<DataCacheType> };
  lq: { q: ListQueryQueryType; data: ListQuery<DataCacheType> };
  user: { q: UserQueryType; data: User };
  uf: { q: UserForeignQueryType; data: ForeignAccount };
  χuf: { q: UserForeignSearchType; data: string };
  χauf: { q: VirtualAllForeignQueryType; data: List<"uf"> };
  role: { q: RoleQueryType; data: UserRole };
  gas: { q: GasQueryType; data: GasPriceData };
  balance: { q: BalanceQueryType<ChainType>; data: BalanceData };
  tokens: { q: TokensListQueryType; data: TokenItem[] };
  org: { q: OrgQueryType; data: Organization };
  page: { q: OKWidgetPageQueryType; data: OKWidgetPage };
  person: { q: PersonQueryType; data: Person };
  contract: { q: ContractQueryType; data: SmartContract };
  widget: { q: WidgetQueryType; data: OKWidget };
  token: { q: TokenQueryType; data: OKToken };
  price: { q: TokenMarketQueryType; data: TokenMarketData };
  nft: { q: NFTQueryType; data: OKToken };
  lend: { q: LendQueryType; data: OKToken };
  stake: { q: StakeQueryType; data: OKToken };
  swap: { q: SwapQueryType; data: OKToken };
  abi: { q: ABIQueryType<ChainType>; data: ABI };
  abix: { q: ABIExtraQueryType; data: ABIExtra };
  method: { q: MethodQueryType; data: SmartContract };
  chain: { q: ChainQueryType; data: Chain };
  host: { q: HostQueryType; data: Host };
  χntm: { q: IntentMatchQueryType; data: ListQuery<"widget"> };
  "@searchpage": { q: SearchQueryType<DataCacheType>; data: SearchPage };
  "χcon.anon": {
    q: AnonContractQueryType<ChainType>;
    data: SmartContract | OKToken;
  };
  "χcon.allAnon": {
    q: AllAnonContractQueryType;
    data: { id: string; l: CachedData<"contract">[] };
  };
  "eas.attq": {
    q: EASQueryQueryType;
    data: { data: { attestations: EASAttestation[] } };
  };
  "@indexpage": {
    q:
      | IndexPageQueryType<IndexableTypes>
      | IndexPrefixQueryType<IndexableTypes>
      | IndexPopularQueryType<IndexableTypes>;
    data: IndexPage<IndexableTypes>;
  };
  tCh: {
    q: TokenByChainPageType<ChainType>;
    data: IndexPage<"token">;
  };
  "tCh.pfx": {
    q: TokenByChainPrefixType<ChainType>;
    data: IndexPage<"token">;
  };
  "org.page": {
    q: IndexPageQueryType<"org">;
    data: IndexPage<"org">;
  };
  "widget.page": {
    q: IndexPageQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  "token.page": {
    q: IndexPageQueryType<"token">;
    data: IndexPage<"token">;
  };
  "contract.page": {
    q: IndexPageQueryType<"contract">;
    data: IndexPage<"contract">;
  };
  "person.page": {
    q: IndexPageQueryType<"person">;
    data: IndexPage<"person">;
  };
  "abi.page": {
    q: IndexPageQueryType<"abi">;
    data: IndexPage<"abi">;
  };
  "abix.page": {
    q: IndexPageQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  "chain.page": {
    q: IndexPageQueryType<"chain">;
    data: IndexPage<"chain">;
  };
  "org.pfx": {
    q: IndexPrefixQueryType<"org">;
    data: IndexPage<"org">;
  };
  "widget.pfx": {
    q: IndexPrefixQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  "token.pfx": {
    q: IndexPrefixQueryType<"token">;
    data: IndexPage<"token">;
  };
  "contract.pfx": {
    q: IndexPrefixQueryType<"contract">;
    data: IndexPage<"contract">;
  };
  "person.pfx": {
    q: IndexPrefixQueryType<"person">;
    data: IndexPage<"person">;
  };
  "abi.pfx": {
    q: IndexPrefixQueryType<"abi">;
    data: IndexPage<"abi">;
  };
  "abix.pfx": {
    q: IndexPrefixQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  "org.pop": {
    q: IndexPopularQueryType<"org">;
    data: IndexPage<"org">;
  };
  "widget.pop": {
    q: IndexPopularQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  "token.pop": {
    q: IndexPopularQueryType<"token">;
    data: IndexPage<"token">;
  };
  "contract.pop": {
    q: IndexPrefixQueryType<"contract">;
    data: IndexPage<"contract">;
  };
  "person.pop": {
    q: IndexPopularQueryType<"person">;
    data: IndexPage<"person">;
  };
  "abi.pop": {
    q: IndexPopularQueryType<"abi">;
    data: IndexPage<"abi">;
  };
  "abix.pop": {
    q: IndexPopularQueryType<"widget">;
    data: IndexPage<"widget">;
  };
  upvote: { q: UpvoteQueryType<CacheQueryUpVotable>; data: UpvoteData };
  claim: { q: ClaimQueryType<CacheQueryClaimable>; data: ClaimData };
  upvotes: { q: UpvotesQueryType<CacheQueryUpVotable>; data: number };
  address: {
    q: AddressLookupQueryType<ChainType>;
    data: (ContractQueryType | TokenQueryType)[];
  };
  intf: {
    q: AddressLookupQueryType<ChainType>;
    data: (ContractQueryType | TokenQueryType)[];
  };
  chunk: { q: ChunkQueryType; data: ChunkData };
  part: { q: PartitionQueryType; data: Partition };
  "lr:ord": { q: LooksRareOrderQueryType; data: LooksRareResponse };
  proxy: { q: ProxyQueryType; data: string };
  vProxy: { q: VirtualProxyQueryType; data: string };
  up: { q: IPFSUploadQueryType; data: IPFSUpload };
  url: { q: URLQueryType; data: URLData };
  χtime: { q: TimeLookupQueryType; data: string };
  ntx: { q: NetworkTXQueryType; data: NetworkTX };
  "eas:attr": { q: EASRecipientsQueryType; data: EASAttestation[] };
  // @todo list every query type that can generate an error
  // @todo replace with generated JSON
  error: { q: QueriesWithoutError; data: DataError };
};

type ExcludeErrorKey<T> = Exclude<keyof T, "error">;
type QueriesWithoutError = {
  [K in ExcludeErrorKey<Definitions>]: Definitions[K]["q"];
}[ExcludeErrorKey<Definitions>];

export type DataCacheType = keyof Definitions;
export type CacheQuery = Definitions[DataCacheType]["q"];
export type DataCacheData = Definitions[DataCacheType]["data"];

export type TypeFromCacheQuery<T extends CacheQuery> = {
  [x in DataCacheType]: Definitions[x]["q"] extends T ? x : never;
}[DataCacheType];

export type TypeFromCacheData<T extends DataCacheData> = {
  [x in DataCacheType]: Definitions[x]["data"] extends T ? x : never;
}[DataCacheType];

export type CacheQueryFromType<Key extends DataCacheType> =
  Definitions[Key]["q"];
export type CacheDataFromType<Key extends DataCacheType> =
  Definitions[Key]["data"];

export const GasQuery = (ch: ChainType): GasQueryType => `!${ch}`;
export const BalanceQuery = (
  ch: ChainType,
  tok: string,
  addr: string
): BalanceQueryType<ChainType> => `bal:${ch}:${tok}:${addr}`;

/**
 * TokenMarketDataQuery is used to retrieve coingecko token price data
 * @param tok
 * @returns
 */
export const TokenMarketDataQuery = (tok: string): TokenMarketQueryType =>
  `\$${tok}`;
export const TokenListQuery: TokensListQueryType = "*";
// export const MethodQuery = (
//   org: string,
//   id: string,
//   met: string
// ): MethodQueryType => `met:${org}/${id}:${met}`;
// @todo previously /?
export const MethodQueryFromContractQuery = (
  q: ContractQueryType,
  met: string
): MethodQueryType => `met:${q.slice(4)}/${met}`;
export const WidgetQuery = (id: string): WidgetQueryType => `wid:${id}`;

export const PersonQuery = (id: string): PersonQueryType => `pp:${id}`;
export const SearchQuery = (
  q: string,
  ty?: DataCacheType
): SearchQueryType<DataCacheType> =>
  ty ? `χsearch.${ty}:${q}` : `χsearch:${q}`;
export const SearchUserWidgets = (user: string, q: string) =>
  VirtualQuery("user.ws", `${user} ${q}`);
export const ProxyQuery = (url: string): ProxyQueryType => `χproxy:${url}`;
export const VirtualProxyQuery = (url: string): VirtualProxyQueryType =>
  `χproxy.head:${url}`;
export const URLQuery = (id: string): URLQueryType => `url:${id}`;

export const IndexPageQuery = <T extends IndexableTypes>(
  ty: T,
  page: number,
  size = 50
): IndexPageQueryType<T> => `χ${ty}.page:${page}|${size}`;
export const IndexPopularQuery = <T extends DataCacheType>(
  ty: T,
  page = 0,
  size = 50
): IndexPopularQueryType<T> => `χ${ty}.pop.page:${page}|${size}`;

export const TokenByChainPrefixQuery = <Q extends ChainType>(
  ch: Q,
  pfx: string
): TokenByChainPrefixType<ChainType> => `χ${TokenByChainPrefix}:${ch}|${pfx}`;
export const TokenByChainPageQuery = (
  ch: ChainType,
  page: number
): TokenByChainPageType<ChainType> => `χ${TokenByChainPage}:${ch}:${page}|50`;
export const ABIQuery = <Q extends ChainType>(
  ch: Q,
  addr: Address<Network>
): ABIQueryType<Q> => (addr ? `abi:${ch}:${addr.toString()}` : null);
export const ABIExtraQuery = (id: string): ABIExtraQueryType => `abix:${id}`;
export const UpvoteQuery = <Q extends CacheQueryUpVotable>(
  addr: string | Address<Network>,
  q: Q
): UpvoteQueryType<Q> => `upvote:${addr.toString()}:${q}`; // FIXME: normalize?
export const UpvotesQuery = <Q extends CacheQueryUpVotable>(
  q: Q
): UpvotesQueryType<Q> => `χupvotes:${q}`;
export const ClaimQuery = <Q extends CacheQueryClaimable>(
  // @todo Address
  addr: string,
  q: Q
): ClaimQueryType<Q> => `claim:${addr}:${q}`;
export const ChunkQuery = (addr: string, id: string): ChunkQueryType =>
  `chunk:${addr}:${id}`;
export const AddressLookupQuery = (
  q: string,
  ch?: ChainType
): AddressLookupQueryType<ChainType> =>
  isStringAddress(q)
    ? ch
      ? `χaddress:${getStringAddress(q)}|${ch}`
      : `χaddress:${getStringAddress(q)}`
    : undefined;
export const IPFSUploadQuery = (
  wid: string,
  addr: string,
  seq?: number
): IPFSUploadQueryType =>
  `up:${wid}:${addr}${seq !== undefined ? `:${seq}` : ""}`;
export const TimeLookupQuery = (): TimeLookupQueryType => "χtime:";
export const NetworkTXQuery = (ntx: NetworkTX): NetworkTXQueryType => {
  if (!ntx?.p) return;
  const p = jsonStringify(ntx.p);
  if (!p) return;
  const b64 = btoa(p);
  return `χ${OKWidgetNetworkTXStep}.${ntx.id}:${b64}`;
};

export const AllChainsQuery = (): IndexPageQueryType<"chain"> =>
  IndexPageQuery("chain", 0, 100);
export const ChainQuery = <C extends ChainType>(ch: C): ChainQueryType =>
  `§${ch}`;

// FIXME
// from https://www.typescriptlang.org/play?ssl=55&ssc=96&pln=1&pc=1#code/C4TwDgpgBAShA2BDYBLA9gOwM4AsVigF4oBvAWACgpqpRIAuKAcjiVU13ycoF9LK60AGJoATgFtkwCABNWydNjwFi5KjUGMmIiVNnz2Srr34VBUAMIBXLMDTiAcmhnRVlGrXAQt12-acuzCYUAl5QOpLA0jIBrqTuGl5aEXoxzhDcFHwhOeYAkhhgVsBEljZ2julQAD6wCAocyjVQVhguAGYoGLKhkFAA8sVFJcQpUbKxzWPRBoqcBLWtHV09uWEAsohgAAqisigAxsgQADwAKgB8RAnUZ1AQAB7SbVhQBcM3UAD8UHePzzJXr4KrFPj9phN0p9GH8nhAXnU2HNlGDwmJIjN6oZ5tDfvc4QilhBOt0ZKiiSTVh5GN0AG4QURQGkQemiUzmTZgSAyE6fACCokZ-3hgLqiBkmHgIAFokQIBOrQA1hg0AB3DAXAA0nzgWCs8BKwoRMrlCowyrVGtKAG0ALqfK7EGX4gGvO2o93qaiMZ1G0XWrrtBlQAAS9q93yg1oAdLHdfrgJqoJzdvsjtITiGLuHqVBfQT-YHgyGIOKk7Ho0XGWdECh4DmaD9OdyTjHYzW67ak23o-GDUmU3sZIdjpnSzJsxdcXBxZL5X3gBcANymdqtA6GFpYCBjXkR2Gut6FYraiMAVRdIteM4lGClJvll1PHguAAoEBBxPDgFhGD2z7aACUjDNrIJxnlOFBrhgG6KFuO7osge4eAeV5HsMz40BefqvGcdqYdQb4fl+GA-owZ7AVAiAYCA8QRgcHAlO0iHjDIACi8Cft+v4DEMxR2jatorhGAD0IlQBKUDMboURdAA5hWnx7MAViiBgUksdEHFcaRWDBAx2AlHseoGqUNgITJr7WiQngMMwsyNFwUA8EBUBiWiMmYkijlgJQBm2FAxkJgATGZ25jFZNmaMwwL+FUTDOUmUVJPZWLIk5LmAW54kQmkLhAA

// type Relationship = {
//   type: 'Relationship'
// }

// type FormattedRelationship = {
//   type: 'FormattedRelationship'
// }

// type CustomNode = {
//   type: 'CustomNode '
// }

// type FormattedNode = {
//   type: 'FormattedNode'
// }

// type Input = CustomNode | Relationship | undefined
// type Output = FormattedNode | FormattedRelationship | undefined

// type MapPredicate<T> = T extends Input
//   ? T extends CustomNode
//     ? FormattedNode
//     : T extends Relationship
//     ? FormattedRelationship
//     : T extends undefined
//     ? undefined
//     : never
//   : never;

// // Mapped array of queries results.
// export type Mapped<
//   Arr extends ReadonlyArray<unknown>,
//   Result extends Array<unknown> = []
// > = Arr extends []
//   ? []
//   : Arr extends [infer H]
//   ? [...Result, CachedData<H>]
//   : Arr extends [infer Head, ...infer Tail]
//   ? Mapped<[...Tail], [...Result, CachedData<Head>]>
//   : Readonly<Result>;

// function useFormat<T extends CacheQuery, U extends ReadonlyArray<T>>(
//   elements: [...U]
// ): Mapped<U>;
// function useFormat<T extends CacheQuery, U extends T[]>(elements: U): any {
//   const formattedElements: CachedData[] = [];
//   // do formatting...
//   return formattedElements;
// }
// const result = useFormat([{ type: "Relationship" }]); // FormattedRelationship
// const result2 = useFormat([{ type: "CustomNode " }, { type: "Relationship" }]); // FormattedNode

export type UpvoteData = {
  /** address voting */
  addr: string;
  /** upvoted query */
  for: CacheQueryUpVotable;
};

export type ChunkData = {
  /** address author */
  addr: string;
  json: unknown;
};

/**
 * URLData is a verified URL repository.
 * URLs must be defined by `id` in datacache to retrieve the whitelisted URLs.
 */
export type URLData = {
  /** unique identifier for the URL */
  id: string;
  /** URL value */
  v: string;
};

export type ClaimData = {
  /** claimer */
  addr: string;
  /** claimed query */
  q: string;
  /** status */
  st?: number;
};

const writableTypes = [
  "upvote",
  "claim",
  "widget",
  "up",
  "chunk",
  "contract",
  "token",
  "nft",
  "org",
  "abix",
  "abi",
  "chain",
  "user",
  "uf",
  "page",
  "host",
  "role"
] as const;

// type Subset<T extends readonly unknown[]> = T extends [
//   infer First,
//   ...infer Rest
// ]
//   ? [] | [First] | [First, ...Subset<Rest>] | Subset<Rest>
//   : [];

type writableTypesRecord = Record<(typeof writableTypes)[number], unknown>;

type Subset<T extends Record<string, unknown>> = Partial<T>;

export type AnyWritableDataType = keyof Subset<writableTypesRecord>;

// @todo merge with writable_
// const editableData = [
//   "abi",
//   "abix",
//   "org",
//   "nft",
//   "token",
//   "contract",
//   "widget"
// ] as const;
// export type EditableDataType = (typeof editableData)[number];
export type WritableDataType = (typeof writableTypes)[number];

export type WriteResponse<T extends WritableDataType> = {
  /** Cached data */
  cd: CachedData<T>[];
};

export const is_org = (q: string): q is OrgQueryType =>
  typeof q === "string" && q.startsWith("org:");

export const is_met = (q: string): q is MethodQueryType =>
  typeof q === "string" && q.startsWith("met:");

export const is_person = (q: string): q is PersonQueryType =>
  typeof q === "string" && q.startsWith("pp:");

export const is_widget = (q: string): q is WidgetQueryType =>
  typeof q === "string" && q.startsWith("wid:");

export const is_writable_data = (v: string): v is WritableDataType => {
  return (writableTypes as readonly string[]).indexOf(v) >= 0;
};

export const getType = (
  str: CacheQueryFromType<WritableDataType>,
  isNew: boolean
) =>
  isNew && is_writable_data(str) ? str : write_type(type_of_cache_query(str));

export const get_writable_roles = (q: string, isNew: boolean): Role[] => {
  const type = getType(q as CacheQueryFromType<WritableDataType>, isNew);
  if (["abi", "token"].includes(type)) return [ADMIN, USER, MANAGER];
  return [ADMIN];
};

export const methodFromQuery = (q: MethodQueryType) =>
  is_met(q) ? q.split("/")[2] : null;

export const contractFromQuery = (q: ContractQueryType | MethodQueryType) => {
  const parts = q.split(":");
  if (parts.length > 1) {
    const subparts = parts[1].split("/");
    return `con:${subparts[0]}/${subparts[1]}` as ContractQueryType;
  }
  return null;
};
