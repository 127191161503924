// @todo move here
import type { AnyCell, SheetProxy } from "@okcontract/cells";
import { ChainQuery } from "@okcontract/coredata";
import {
  EDIT,
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition,
  NEW,
  defaultPatterns,
  objectDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { submitter } from "./data-user";
import type { OKPage } from "./instance";

export const chain_groups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "Chain Information" }),
  proxy.new({ id: "rpc", l: "RPCs" }),
  proxy.new({ id: "exp", l: "Explorers" })
];

// export interface Chain {
//   /** OKcontract ID (without prefix) */
//   id: ChainType;
//   /** display name */
//   name: string;
//   /** chain ID */
//   numid: bigint; // cspell:disable-line
//   /** network */
//   net: Network;
//   /** testnet */
//   test?: boolean;
//   /** currency OKcontract query */
//   currency: TokenQuery;
//   /** list of RPCs as string */
//   rpc: string[];
//   /** list of explorers */
//   explorer: string[];
//   /** default gas value */
//   defaultGas?: number;
//   /** optional logo */
//   readonly logo?: string;
// }

// @todo seems wrong...
// @todo why from?
export const chainIDSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(
      instance.proxy,
      {
        from: submitter(),
        id: {
          label: "Unique chain ID",
          locked: mode === EDIT,
          base: "string",
          pattern: defaultPatterns.orgid,
          gr: "id",
          pl: "ethereum",
          search: mode === EDIT ? "chain" : undefined,
          hint: mode === NEW ? "Unique chain identifier" : undefined,
          help:
            mode === NEW
              ? "Should be a unique identifier for the chain"
              : undefined,
          validator: async (v: string) => {
            // TODO: refactor with org / contract / token
            if (!v) return;
            const ch = await instance.core.CacheOnce(ChainQuery(v));
            if (ch?.id && mode === NEW) {
              return `Unique ID ${v} already exists`;
            }
          }
        }
      },
      "Chain"
    );

export const chainSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance.proxy, {
      id: {
        label: "ID",
        base: "string",
        gr: "id"
      },
      name: {
        label: "Name",
        base: "string",
        gr: "id"
      },
      logo: {
        label: "Chain logo",
        base: "string",
        isImg: true,
        pl: "https://... or ipfs://...",
        gr: "id"
      },
      net: {
        label: "Network",
        enum: ["evm", "strk"],
        gr: "id"
      },
      numid: {
        label: "Chain ID",
        base: "number",
        gr: "id",
        css: "min-w-64"
      },
      currency: {
        label: "Currency",
        search: "token",
        base: "string",
        gr: "id"
      },
      explorer: {
        label: "",
        gr: "exp",
        array: () =>
          instance.proxy.new({
            label: "Url",
            base: "string",
            min: 1,
            pl: "https://sepolia.etherscan.io"
          } as LabelledTypeDefinition)
      },
      rpc: {
        label: "",
        gr: "rpc",
        array: () =>
          instance.proxy.new({
            label: "Url",
            base: "string",
            min: 1,
            pl: "https://rpc.sepolia.org"
          } as LabelledTypeDefinition)
      }
    });
