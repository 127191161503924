<script lang="ts">
  import { ChainQuery } from "@okcontract/coredata";
  import {
    OKTransaction,
    type AnyContractQuery,
    type OKPage
  } from "@okcontract/sdk";
  import { getTheme } from "@okcontract/uic";

  import ChainButton from "./ChainButton.svelte";
  import SwitchChains from "./SwitchChains.svelte";
  import WalletAccounts from "./WalletAccounts.svelte";
  import WidgetSettings from "./WidgetSettings.svelte";

  export let instance: OKPage;
  export let switcher = true;

  const wantedChain = instance.wantedChain;
  const proxy = instance.proxy;
  const core = instance.core;
  const selected = proxy.map(
    [core.Chains, instance.wantedChain],
    (all, ch) => all[ChainQuery(ch)]
  );

  export let okTX: OKTransaction<AnyContractQuery>;
  const chains = proxy.map(
    [okTX.chains],
    (all) => Array.from(all.keys()),
    "chains"
  );
  // $: okTX && console.log({ $chains, title: okTX.title.value, okTX });

  export let theme = getTheme();

  const open = proxy.new(false, "WidgetHeader.open");
</script>

<div class="grid h-14 z-10 w-full">
  <div class="flex gap-1 items-center justify-end px-3 py-2">
    <div class="flex gap-1 items-center">
      <!--       {$selected?.id}
      {$wantedChain} -->
      {#if switcher}
        <SwitchChains
          current={selected}
          {instance}
          {chains}
          displayCurrent={true}
          size="sm"
          small={true}
        />
      {:else}
        <ChainButton {instance} chain={$wantedChain} />
      {/if}
      <WalletAccounts {instance} {open} />
      <WidgetSettings {theme} {instance} small={true} />
    </div>
  </div>
</div>
